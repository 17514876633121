import React, { useEffect, useState, useRef } from 'react'

import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useCreateLanguageMutation } from '../../../../Redux/Language/LanguageAPI';

export default function AddLanguage({ changeView }) {
    const addLanguageRef = useRef(undefined)

    const [createLanguage, { isLoding, error, isSuccess }] = useCreateLanguageMutation();

    const [language, setLanguage] = useState({
        name: '',
    });

    const [localError, setLocalError] = useState('');

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleChange = (e, key) => {
        setLanguage({
            ...language,
            [key]: e.target.value
        })
    }

    //validate if name is empty or not
    const validate = () => {
        if (language.name === '') {
            return false
        } else {
            return true
        }
    }

    const handleAddLanguage = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                createLanguage(language)
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in all fields')
        }
    }

    return (
        <Box
            component='form'
            ref={addLanguageRef}
            onSubmit={handleAddLanguage}
            className='add-language-form card-box'
        >
            <TextField
                label='Language Name'
                variant='outlined'
                value={language.name}
                onChange={(e) => handleChange(e, 'name')}
            />
            <CustomButton
                buttonName='Add Language'
                onClick={() => { }}
                type='submit'
            />
            <CustomButton
                buttonName='Cancel'
                onClick={changeView}
                icon={<CancelIcon />}
                classname='danger'
            />
            {localError && <p>{localError}</p>}
        </Box>
    )
}