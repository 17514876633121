import React, { useEffect, useState } from 'react'
import logo from '../../../Assets/logo.png'
import sideImg from '../../../Assets/login-side-img.png'
import { useLoginMutation } from '../../../Redux/User/UserAPI'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    localStorage.setItem('login_status', '')
    localStorage.setItem('user_info', '')
    document.body.setAttribute('id', '')

    const navigate = useNavigate()

    const [login, { data, isLoading, error, isSuccess }] = useLoginMutation()

    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [localError, setLocalError] = useState('')

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('login_status', 'User has logged in.');
            localStorage.setItem('user_info', JSON.stringify(data.user))
            navigate('/classroom')
        }
    }, [isSuccess])

    useEffect(() => {
        if (error) setLocalError(error.data.message)
    }, [error])

    const handlePhoneNumber = e => {
        setLocalError('')
        setPhoneNumber(e.target.value)
    }

    const handlePassword = e => {
        setLocalError('')
        setPassword(e.target.value)
    }

    //validate phone number and password to check if they are empty or not
    const validate = () => {
        if (phoneNumber === '' || password === '') {
            setLocalError('Please fill all the fields')
            return false
        } else if (phoneNumber.length < 9) {
            setLocalError('Please enter a valid phone number')
            return false
        } else {
            return true
        }
    }

    //handle login
    const handleLogin = e => {
        setLocalError('')
        e.preventDefault()
        if (validate()) {
            login({
                phoneNumber: phoneNumber,
                password: password,
            })
        }
    }

    return (
        <div id='login-public' className='container py-5'>
            <div className='logo'>
                <a href='/'>
                    <img src={logo} alt='' className='img-fluid'></img>
                    <span>- 8832</span>
                </a>
            </div>
            <div className='row my-5'>
                <div className='col-md-5'>
                    <div className='form-section p-5'>
                        <h3>Welcome</h3>
                        <h1>
                            Log in to <br></br>
                            <span>BitApps E-Learning</span>
                        </h1>
                        <p>
                            Send OK to 8832 and kick-start your career growth!
                        </p>
                        <form className=''>
                            <div className='input-section py-2'>
                                <input
                                    className='phone-number'
                                    placeholder='Phone Number'
                                    type='text'
                                    onChange={handlePhoneNumber}
                                    value={phoneNumber}
                                    required
                                ></input>
                                <input
                                    className='password'
                                    placeholder='Password'
                                    onChange={handlePassword}
                                    value={password}
                                    required
                                ></input>
                            </div>
                            <div className='auth-options d-flex flex-row justify-content-between'>
                                <div className='remember-me'>
                                    <input
                                        id='remember-me'
                                        type='checkbox'
                                    ></input>
                                    <label htmlFor='remember-me'>
                                        Remember Me
                                    </label>
                                </div>

                                {/* <div className='forgot-password'>
                                    <a href='/'>Forgot Password?</a>
                                </div> */}
                            </div>

                            <div className='button'>
                                <div className='error-message'>
                                    {localError != '' && localError}
                                </div>
                                <button onClick={handleLogin}>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='img-section col-md-7'>
                    <img src={sideImg} />
                    <p>8832</p>
                </div>
            </div>
        </div>
    )
}
