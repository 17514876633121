const baseQuery = {
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = localStorage.getItem('jwt_token')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        // headers.set('Content-Type', 'application/json')

        return headers
    },
}

export default baseQuery
