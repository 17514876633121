import React, { useEffect, useState, useRef } from 'react'

import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useUpdateCourseMutation, useGetCourseByIdQuery, useUploadImageMutation, useUpdateCourseCategoriesMutation } from '../../../../Redux/Course/CourseAPI';

import { useGetCourseCategoriesQuery } from '../../../../Redux/CourseCategory/CourseCategoryAPI';

import { getImageSrc } from '../../../../Util/util'
export default function EditCourse({ editCourseId, changeView }) {
    const editCourseRef = useRef(undefined)

    const [updateCourse, { isLoding, error, isSuccess }] = useUpdateCourseMutation();
    const [uploadImage, { isLoding: imageIsLoading, error: imageError, isSuccess: imageIsSuccess }] = useUploadImageMutation();
    const [updateCategories, { isLoding: updateCategoriesIsLoading, error: updateCategoriesError, isSuccess: updateCategoriesIsSuccess }] = useUpdateCourseCategoriesMutation();


    const [course, setCourse] = useState({
        title: '',
        shortDescription: '',
        longDescription: '',
        imgSrc: '',
        isPublic: false
    });

    const [courseImage, setCourseImage] = useState({
        imgSrc: null
    })
    const [imgSrc, setImgSrc] = useState(null)

    const [selectedCategories, setSelectedCategories] = useState(new Set([]));

    const [localError, setLocalError] = useState();

    const { data: courseData, error: courseError, isLoading: courseIsLoading } = useGetCourseByIdQuery(editCourseId);
    const { data: courseCategories, error: courseCategoryError, isLoading: courseCategoryIsLoading } = useGetCourseCategoriesQuery();

    useEffect(() => {
        if (courseData) {
            setCourse(courseData)
            setSelectedCategories(new Set(courseData.CourseCategories.map(category => category.id)))
        }
    }, [courseData])

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])


    const handleInputChange = (e, key) => {
        const { value, type, checked } = e.target;
        setCourse({
            ...course,
            [key]: type === 'checkbox' ? checked : value
        });
    }

    const validate = () => {
        if (course.title === '' || course.shortDescription === '' || course.longDescription === '') {
            return false
        } else {
            return true
        }
    }

    const handleImageChange = (e) => {
        if (e.target.files.length === 0) return;
        const file = e.target.files[0] || e.dataTransfer.files[0];
        setCourseImage({ imgSrc: file });

        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result);
        };
        reader.readAsDataURL(file);
    }

    //handle adding categoriy change
    const handleCategoryChange = (e) => {
        const { value, checked } = e.target;


        if (checked) {
            setSelectedCategories((sc) => new Set(sc).add(parseInt(value)));
        } else {
            setSelectedCategories((sc) => new Set([...sc].filter(x => x !== parseInt(value))));
        }
    }


    const handleEditCourse = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                updateCourse(course)
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in all fields')
        }
    }

    const handleUpdateImage = (e) => {
        e.preventDefault();
        const data = new FormData();

        Object.keys(courseImage).forEach(key => {
            data.append(key, courseImage[key]);
        });
        try {
            uploadImage({
                id: course.id,
                data: data
            });
        } catch (e) {
            console.log(e)
        }
    }

    //handle update categories
    const handleUpdateCategories = (e) => {
        e.preventDefault();
        try {
            updateCategories({
                id: course.id,
                data: [...selectedCategories]
            })
        } catch (e) {
            console.log(e)
        }
    }



    console.log(selectedCategories)
    console.log(courseCategories)

    if (courseIsLoading) return <h1>Loading...</h1>

    return (
        <div>
            <Box
                component='form'
                className='add-course-form card-box'
                ref={editCourseRef}
                onSubmit={handleEditCourse}
            >

                <TextField
                    label='Title'
                    variant='outlined'
                    value={course.title}
                    onChange={(e) => handleInputChange(e, 'title')}
                />
                <TextField
                    label='Short Description'
                    variant='outlined'
                    value={course.shortDescription}
                    onChange={(e) => handleInputChange(e, 'shortDescription')}
                />
                <TextField
                    label='Long Description'
                    variant='outlined'
                    value={course.longDescription}
                    onChange={(e) => handleInputChange(e, 'longDescription')}
                />
                <FormControlLabel
                    control={<Checkbox onChange={(e) => handleInputChange(e, 'isPublic')} checked={course.isPublic} />}
                    label='Is Public'
                />
                {
                    course?.imgSrc && <img src={getImageSrc(course.imgSrc)} alt="course" />
                }


                <CustomButton buttonName="Save Course" onClick={() => { }} type="submit" icon={<SaveIcon />} />
                <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
                {localError && <p className='error'>{localError}</p>}
                {error?.data?.message && <p className='error'>{error?.data?.message}</p>}
            </Box>


            {/* create a checkbox group of list of categories and a save/cancel button */}

            <Box
                component='form'
                className='add-course-form card-box'
                onSubmit={handleUpdateCategories}
            >
                {
                    courseCategories?.map((category, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox onChange={(e) => handleCategoryChange(e)} />}
                                label={category.name}
                                value={parseInt(category.id)}
                                checked={selectedCategories.has(category.id)}
                            />
                        )
                    })
                }
                <CustomButton buttonName="Save Categories" onClick={() => { }} type="submit" icon={<SaveIcon />} />
                <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
            </Box>

            {/* create a field for an image upload where you can also drag and drop */}
            <Box
                component='form'
                className='add-course-form upload-image-box card-box'
                onSubmit={handleUpdateImage}
            >
                <div className='input-wrapper' onDrop={handleImageChange} onDragOver={(e) => e.preventDefault()}>
                    <input
                        accept="image/*"
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleImageChange}

                    />

                    <label htmlFor="raised-button-file">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {
                                imgSrc ? <img src={imgSrc} alt="course" /> :
                                    (<>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <CloudUploadIcon />
                                        </IconButton>
                                        <Typography>Drag and drop files here or click to select files</Typography>
                                    </>)
                            }


                        </Box>
                    </label>
                </div>


                <CustomButton buttonName={!course?.imgSrc ? 'Add Image' : 'Save Image'} onClick={() => { }} type="submit" icon={<SaveIcon />} />
                <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
            </Box >


        </div >
    )

}

