import React, { useState } from 'react'

import CustomButton from "../../../../Components/AdminComponents/CustomButton/CustomButton";
import AddCourseCategory from './AddCourseCategory';
import CourseCategoryList from './CourseCategoryList';
import EditCourseCategory from './EditCourseCategory';

// import CourseCategoryData from "../../../../Data/DataCourseCategories";

import { useGetCourseCategoriesQuery } from '../../../../Redux/CourseCategory/CourseCategoryAPI';

export default function CourseCategory() {

    const [addCourseCategoryView, setAddCourseCategoryView] = useState(false);
    const [editCourseCategoryView, setEditCourseCategoryView] = useState(false);
    const [editCourseCategoryId, setEditCourseCategoryId] = useState(null);


    const { data: CourseCategoryData, error, isLoading } = useGetCourseCategoriesQuery();


    if (isLoading) return <h1>Loading...</h1>

    return (
        <div className='course-category-container'>
            <h1>Course Category</h1>
            {!addCourseCategoryView && !editCourseCategoryView && <CustomButton buttonName={"Add Course Category"} onClick={() => setAddCourseCategoryView(!addCourseCategoryView)} />}
            {addCourseCategoryView && <AddCourseCategory changeView={() => setAddCourseCategoryView(!addCourseCategoryView)} />}
            {editCourseCategoryView && <EditCourseCategory editCourseCategoryId={editCourseCategoryId} changeView={() => setEditCourseCategoryView(!editCourseCategoryView)} />}
            {(!addCourseCategoryView && !editCourseCategoryView) && <CourseCategoryList courseCategories={CourseCategoryData} changeView={() => setEditCourseCategoryView(!editCourseCategoryView)} setEditCourseCategoryId={setEditCourseCategoryId} />}

        </div>
    )
}
