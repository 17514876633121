import React, { useState } from 'react'
import CustomButton from "../../../../Components/AdminComponents/CustomButton/CustomButton";
import AddCourse from './AddCourse';
import CourseList from './CourseList';
import EditCourse from './EditCourse';

import DataCourses from "../../../../Data/DataCourses";

import { useGetCoursesQuery } from '../../../../Redux/Course/CourseAPI';


export default function Courses() {

    const [addCourseView, setAddCourseView] = useState(false);
    const [editCourseView, setEditCourseView] = useState(false);
    const [editCourseId, setEditCourseId] = useState(null);

    const { data: courseData, error, isLoading } = useGetCoursesQuery();

    if (isLoading) return <h1>Loading...</h1>

    return (
        <div className='course-container'>
            <h1>Courses</h1>
            {!addCourseView && !editCourseView && <CustomButton buttonName={"Add Course"} onClick={() => setAddCourseView(!addCourseView)} />}
            {addCourseView && <AddCourse changeView={() => setAddCourseView(!addCourseView)} />}
            {editCourseView && <EditCourse editCourseId={editCourseId} changeView={() => setEditCourseView(!editCourseView)} />}
            {(!addCourseView && !editCourseView) && <CourseList courses={courseData} changeView={() => setEditCourseView(!editCourseView)} setEditCourseId={setEditCourseId} />}

        </div>
    )


}
