import { RouterProvider } from 'react-router-dom'
import router from './router'

function App() {
    return (
        <div className='App'>
            <div className='main-container'>
                <RouterProvider router={router} />
            </div>
        </div>
    )
}

export default App
