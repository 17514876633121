import img_1 from '../Assets/cover-eng-three.jpg'
import img_2 from '../Assets/cover-eng.jpg'
import img_3 from '../Assets/cover-eng-two.jpg'
import img_4 from '../Assets/cover-amh-two.jpg'
import img_5 from '../Assets/cover-amh.jpg'
import img_6 from '../Assets/cover-amh-three.jpg'
import img_7 from '../Assets/oro-two.jpg'
import img_8 from '../Assets/oro-one.jpg'
import img_9 from '../Assets/oro-three.jpg'

let Data = [
    {
        title: 'Introduction to Data Science',
        img: img_1,
        category: 'Data Science',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Explore the fundamentals of data science, including data manipulation, visualization, and basic machine learning techniques.',
    },
    {
        title: 'Full-Stack Web Development',
        img: img_2,
        category: 'Web Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn how to build modern web applications from front-end to back-end, using popular programming languages and frameworks.',
    },
    {
        title: 'Mobile App Development Fundamentals',
        img: img_3,
        category: 'Mobile App Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Get started with mobile app development, covering both Android and iOS platforms, and learn about app design and deployment.',
    },
    {
        title: 'Graphic Design Principles',
        img: img_4,
        category: 'Graphic Design',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Discover the essential principles of graphic design, including layout, typography, color theory, and visual communication.',
    },
    {
        title: 'Machine Learning Foundations',
        img: img_5,
        category: 'Artificial Intelligence',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Build a solid foundation in machine learning concepts and algorithms, and learn how to apply them to real-world problems.',
    },
    {
        title: 'Cybersecurity Essentials',
        img: img_6,
        category: 'Cybersecurity',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Understand the basics of cybersecurity, including network security, encryption, and best practices to protect against cyber threats.',
    },
    {
        title: 'Game Development with Unity',
        img: img_7,
        category: 'Game Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Create 2D and 3D games using Unity game engine, and learn about game mechanics, physics, and game optimization.',
    },
    {
        title: 'Cloud Computing Basics',
        img: img_8,
        category: 'Web Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Explore the fundamentals of cloud computing, including cloud architecture, deployment models, and cloud service providers.',
    },
    {
        title: 'iOS App Development',
        img: img_9,
        category: 'Mobile App Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn to develop native iOS apps using Swift programming language and Xcode IDE.',
    },
    {
        title: 'User Interface (UI) Design',
        img: img_1,
        category: 'Graphic Design',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Master the art of creating visually appealing and user-friendly interfaces for digital products.',
    },
    {
        title: 'Deep Learning Fundamentals',
        img: img_2,
        category: 'Artificial Intelligence',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Dive deep into advanced neural networks and deep learning models for complex data analysis and predictions.',
    },
    {
        title: 'Network Security',
        img: img_3,
        category: 'Cybersecurity',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn advanced techniques to secure computer networks and defend against cyber attacks.',
    },
    {
        title: '3D Game Development',
        img: img_4,
        category: 'Game Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Explore advanced techniques to create immersive 3D games with realistic graphics and gameplay.',
    },
    {
        title: 'Responsive Web Design',
        img: img_5,
        category: 'Web Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn to design websites that adapt and respond to different screen sizes and devices.',
    },
    {
        title: 'Android App Development',
        img: img_6,
        category: 'Mobile App Development',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn to develop native Android apps using Java or Kotlin programming languages.',
    },
    {
        title: 'Digital Illustration',
        img: img_7,
        category: 'Graphic Design',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Master the art of digital illustration and create stunning visuals using graphic design software.',
    },
    {
        title: 'Natural Language Processing (NLP) Applications',
        img: img_8,
        category: 'Artificial Intelligence',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Apply NLP techniques to build chatbots, language translation systems, and sentiment analysis tools.',
    },
    {
        title: 'Web Application Security',
        img: img_9,
        category: 'Cybersecurity',
        videoLink: 'https://www.youtube.com/embed/9QxX_C3GRZk',
        desc: 'Learn to identify and fix security vulnerabilities in web applications to prevent attacks.',
    },
]

export default Data
