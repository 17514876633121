import React from 'react';

import ImgStudents from "../../../Assets/stats-face.png";
import ImgBook from "../../../Assets/Stats-book.svg";
import ImgWorld from "../../../Assets/stats-world.svg";


const statsData = [
    {
        img: ImgStudents,
        number: 253085,
        title: "Students Enrolled"
    },
    {
        img: ImgBook,
        number: 1205,
        title: "Courses"
    },
    {
        img: ImgWorld,
        number: 127,
        title: "Countries"
    }
]

export default function Stats() {
    return (
        <div id="stats" className='container py-5'>

            <div className='row'>
                {
                    statsData.map((st, i) => {
                        return (
                            <div key={i} id={`stat-item-${i}`} className='stat-item col-md-4 d-flex justify-content-center flex-column align-items-center'>
                                <img src={st.img}></img>
                                <p className='num'>{st.number}</p>
                                <p className='title'>{st.title}</p>
                            </div>
                        )
                    })
                }

                <div className='waves'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
