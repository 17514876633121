import React, { useEffect, useState, useRef } from 'react'

import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useUpdateCourseCategoryMutation, useGetCourseCategoryByIdQuery } from '../../../../Redux/CourseCategory/CourseCategoryAPI';

export default function EditCourseCategory({ editCourseCategoryId, changeView }) {
    const editCourseCategoryref = useRef(undefined)

    const [updateCourseCategory, { isLoding, error, isSuccess }] = useUpdateCourseCategoryMutation();

    const [category, setCategory] = useState({
        name: '',
        description: ''
    });

    const [localError, setLocalError] = useState('');

    const { data: courseCategoryData, error: courseCategoryError, isLoading: courseCategoryIsLoading } = useGetCourseCategoryByIdQuery(editCourseCategoryId);


    useEffect(() => {
        if (courseCategoryData) {
            setCategory(courseCategoryData)
        }
    }, [courseCategoryData])

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleChange = (e, key) => {
        setCategory({
            ...category,
            [key]: e.target.value
        })
    };

    const validate = () => {
        if (category.name === '') {
            return false
        } else {
            return true
        }
    }

    const handleEditCourseCategory = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                updateCourseCategory(category)
            } catch (e) {
                console.log(e)
            }

        } else {
            setLocalError('Please fill in the name field')
        }
    }

    if (isLoding) return <h1>Loading...</h1>

    console.log(category)

    return (
        <Box
            component='form'
            className='edit-course-form card-box'
            onSubmit={handleEditCourseCategory}
            ref={editCourseCategoryref}
        >
            <TextField
                id="outlined-basic"
                label="Name"
                variant="filled"
                value={category.name}
                onChange={(e) => handleChange(e, 'name')}
            />
            <TextField
                id="outlined-basic"
                label="Description"
                variant="filled"
                multiline={true}
                minRows={4}
                value={category.description}
                onChange={(e) => handleChange(e, 'description')}
            />
            <CustomButton buttonName={"Update"} type='submit' onClick={() => { }} />
            <CustomButton
                buttonName='Cancel'
                onClick={changeView}
                icon={<CancelIcon />}
                classname='danger'
            />

            {localError && <p>{localError}</p>}
            {error?.data?.message && <p>{error?.data?.message}</p>}
        </Box>
    )
}
