import React from 'react'
import discord from '../../Assets/discord.png';
export default function Footer() {
  return (
    <footer id='footer'>
      <div className='footer-top'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-8 footer-info'>
              <h3>bitapps</h3>
              <p>
                Unlock Your Potential with Programming - Empowering Ethiopia.
                Explore our comprehensive resources and join our vibrant
                community to learn, code, and create a brighter future together.
              </p>
            </div>
            <div className='col-lg-2 col-md-6 footer-links'>
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/about'>About</a>
                </li>
                <li>
                  <a href='/contactUs'>Contact</a>
                </li>
                <li>
                  <a href='/login'>Login</a>
                </li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-8 footer-contact'>
              <h4>Contact Us</h4>
              <p>
                Cape Verde St - <br></br>
                Salfaz Building 5th Floor<br></br>
                Addis Ababa, Ethiopia <br></br>
                <strong>Phone:</strong>+251 90 946 5857 | +251 91 099 0147
                <br></br>
                <strong>Email:</strong> info@bitappstech.com<br></br>
              </p>
              <div className='social-links'>
                <a href='https://discord.com/channels/1012263973766303804/1012263973766303807' className='discord' target='_blank'><img alt='Discord' src={discord} width='24' height='24'></img></a>
                <a href='https://t.me/bitApps' className='telegram' target='_blank'><i className='fa fa-telegram'></i></a>
                <a href='https://www.facebook.com/bitappstech' className='facebook' target='_blank'><i className='fa fa-facebook'></i></a>
                <a href='https://www.instagram.com/bitapps.tech/' className='instagram' target='_blank'><i className='fa fa-instagram'></i></a>
                <a href='https://www.linkedin.com/company/bitappstech' className='linkedin' target='_blank'><i className='fa fa-linkedin'></i></a>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 footer-newsletter'>
              <h4>Our Newsletter</h4>
              <p>
                We'll send you newsletters, product updates, event alerts, and
                promotional emails on a regular basis. Your e-mail address will
                not be used for anything other than delivering you the emails
                you requested.
              </p>
              <form action='contact.php' method='POST'>
                <input type='email' name='email'></input>
                <input type='submit' name='subscribe' value='Subscribe'></input>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='copyright'>
          © Copyright <strong><a href='https://bitappstech.com/'>BitApps Tech</a> - {(new Date()).getFullYear()}</strong>. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}
