import { useState } from 'react'

import {
    Row,
    Col,
    Card,
    Button,
    List,
    Descriptions,
    Avatar,
    Radio,
    Switch,
    Upload,
    message,
} from 'antd'

import {
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
    VerticalAlignTopOutlined,
} from '@ant-design/icons'

import BgProfile from '../../../Assets/bg-profile.png'
import profilavatar from '../../../Assets/team-2.jpg'

function Profile() {
    const [imageURL, setImageURL] = useState(false)
    const [, setLoading] = useState(false)

    const getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    const beforeUpload = file => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!')
        }
        return isJpgOrPng && isLt2M
    }

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setLoading(false)
            return
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl => {
                setLoading(false)
                setImageURL(false)
            })
        }
    }

    const pencil = [
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            key={0}
        >
            <path
                d='M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z'
                className='fill-gray-7'
            ></path>
            <path
                d='M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z'
                className='fill-gray-7'
            ></path>
        </svg>,
    ]

    const uploadButton = (
        <div className='ant-upload-text font-semibold text-dark'>
            {<VerticalAlignTopOutlined style={{ width: 20, color: '#000' }} />}
            <div>Upload New Project</div>
        </div>
    )

    return (
        <>
            <div
                className='profile-nav-bg'
                style={{ backgroundImage: 'url(' + BgProfile + ')' }}
            ></div>

            <Card
                className='card-profile-head'
                bodyStyle={{ display: 'none' }}
                title={
                    <Row
                        justify='space-between'
                        align='middle'
                        gutter={[24, 0]}
                    >
                        <Col span={24} md={12} className='col-info'>
                            <Avatar.Group>
                                <Avatar size={74} shape='square' />

                                <div className='avatar-info'>
                                    <h4 className='font-semibold m-0'>
                                        Sarah Jacob
                                    </h4>
                                    {/* <p>CEO / Co-Founder</p> */}
                                </div>
                            </Avatar.Group>
                        </Col>
                        <Col
                            span={24}
                            md={12}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        ></Col>
                    </Row>
                }
            ></Card>

            <Row gutter={[24, 0]}>
                <Col span={24} md={8} className='mb-24 '>
                    <Card
                        bordered={false}
                        className='header-solid h-full'
                        title={
                            <h6 className='font-semibold m-0'>
                                Platform Settings
                            </h6>
                        }
                    >
                        <ul className='list settings-list'>
                            <li>
                                <h6 className='list-header text-sm text-muted'>
                                    ACCOUNT
                                </h6>
                            </li>
                            <li>
                                <Switch defaultChecked />

                                <span>Email me when someone follows me</span>
                            </li>
                            <li>
                                <Switch />
                                <span>Email me when someone answers me</span>
                            </li>
                            <li>
                                <Switch defaultChecked />
                                <span>Email me when someone mentions me</span>
                            </li>
                            <li>
                                <h6 className='list-header text-sm text-muted m-0'>
                                    APPLICATION
                                </h6>
                            </li>
                            <li>
                                <Switch defaultChecked />
                                <span>New launches and projects</span>
                            </li>
                            <li>
                                <Switch defaultChecked />
                                <span>Monthly product updates</span>
                            </li>
                            <li>
                                <Switch defaultChecked />
                                <span>Subscribe to newsletter</span>
                            </li>
                        </ul>
                    </Card>
                </Col>
                <Col span={24} md={8} className='mb-24'>
                    <Card
                        bordered={false}
                        title={
                            <h6 className='font-semibold m-0'>
                                Profile Information
                            </h6>
                        }
                        className='header-solid h-full card-profile-information'
                        extra={<Button type='link'>{pencil}</Button>}
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
                    >
                        <p className='text-dark'>
                            {' '}
                            Hi, I’m Alec Thompson, Decisions: If you can’t
                            decide, the answer is no. If two equally difficult
                            paths, choose the one more painful in the short term
                            (pain avoidance is creating an illusion of
                            equality).{' '}
                        </p>
                        <hr className='my-25' />
                        <Descriptions title='Oliver Liam'>
                            <Descriptions.Item label='Full Name' span={3}>
                                Sarah Emily Jacob
                            </Descriptions.Item>
                            <Descriptions.Item label='Mobile' span={3}>
                                (44) 123 1234 123
                            </Descriptions.Item>
                            <Descriptions.Item label='Email' span={3}>
                                sarahjacob@mail.com
                            </Descriptions.Item>
                            <Descriptions.Item label='Location' span={3}>
                                USA
                            </Descriptions.Item>
                            <Descriptions.Item label='Social' span={3}>
                                <a href='#pablo' className='mx-5 px-5'>
                                    {<TwitterOutlined />}
                                </a>
                                <a href='#pablo' className='mx-5 px-5'>
                                    {
                                        <FacebookOutlined
                                            style={{ color: '#344e86' }}
                                        />
                                    }
                                </a>
                                <a href='#pablo' className='mx-5 px-5'>
                                    {
                                        <InstagramOutlined
                                            style={{ color: '#e1306c' }}
                                        />
                                    }
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Profile
