//create a course list component

import React, { useState, useEffect } from 'react'

import VideoTile from '../../../Components/ClassroomComponents/VideoTile/VideoTile';
import { useGetVideosByCourseAndLanguageQuery } from '../../../Redux/Video/VideoAPI'



export default function MyCoursesList({ course, language }) {

    const { data: Videos, error: videoError, isLoading: videoLoading } = useGetVideosByCourseAndLanguageQuery({ courseId: course.id, languageId: language.id });

    const [videoList, setVideoList] = useState([])

    useEffect(() => {
        if (Videos) {
            setVideoList([...Videos].sort((a, b) => a.order - b.order))
        }
    }, [Videos])

    console.log(Videos)

    var renderVid = () => {
        //sort video by order
        if (videoList) {

            return (videoList.slice(0, 5).map((video, i) => {
                return (
                    <VideoTile video={video} languageId={language.id} key={i} />
                )
            })
            )
        }
    }

    if (videoLoading) {
        return (<div> Loading... </div>)
    }


    return (
        <div className='course'>
            <div className=''>
                <h4>{course.title} - {language.name}</h4>
                <div className='course-category'>
                    <p>{course.CourseCategories.map((cc, i) => {
                        if (i === course.CourseCategories.length - 1) {
                            return cc.categoryName
                        } else {
                            return cc.categoryName + ', '
                        }
                    })}</p>
                </div>
            </div>
            <div className='course-content'>
                <p>{course.shortDescription}</p>
                <div className=' row'>
                    {
                        renderVid()
                    }
                    <div className='learn-more col-md-6 col-sm-12 col-lg-4'>
                        <div className='learn-more-button'>
                            <a href={`/classroom/courses/${course.id}/${language.id}`}>Show All</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
