import React, { useState, useEffect } from 'react'

import MyCoursesList from './MyCoursesList'
import CourseVideo from '../../../Components/ClassroomComponents/CourseVideo/CourseVideo'

import { useGetCoursesQuery } from '../../../Redux/Course/CourseAPI'
import { useGetLanguagesQuery } from '../../../Redux/Language/LanguageAPI'

import img_1 from '../../../Assets/cover-eng-one.jpg'
import vid_1 from '../../../Assets/Videos/English-One.mp4'
import img_2 from '../../../Assets/cover-eng-three.jpg'
import vid_2 from '../../../Assets/Videos/English-Two.mp4'

const Data = [
    {
        title: 'Introduction to Programming',
        img: img_1,
        category: 'Programming Basics',
        videoLink: vid_1,
        desc: `Introduction to Programming: Get started on your coding journey with this comprehensive introduction to programming. In this video, you'll learn the fundamental concepts of programming, including variables, data types, control structures, and more. Whether you're a complete beginner or looking to refresh your programming skills, this video will provide you with the essential foundation to start writing and understanding code. Join us as we demystify the world of programming and set you on the path to becoming a proficient programmer.`,
    },
    {
        title: 'How To Start Programming',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Start Programming: Embarking on your programming journey can be both exciting and a little daunting. In this enlightening video, we guide you through the essential steps to kickstart your programming adventure. We cover everything from choosing the right programming language for your goals and setting up your development environment to writing your first lines of code. Whether you're curious about app development, web programming, or simply want to understand the logic behind software, this video will equip you with the knowledge and confidence to take your first steps into the world of programming. Join us and unlock the door to a universe of creative possibilities through coding.`,
    },
]

export default function MyCourses() {
    const { data: Courses, error: courseError, isLoading, courseLoading } = useGetCoursesQuery();
    const { data: Languages, error: langError, isLoading: langLoading } = useGetLanguagesQuery();

    const [languageIds, setLanguageIds] = useState([])

    useEffect(() => {
        console.log(Courses)
    }, [Courses])

    useEffect(() => {
        if (Languages) setLanguageIds(Languages.map(l => l.id))
    }, [Languages])


    console.log(languageIds);

    return (
        <div id='my-courses' className='container py-5'>
            <div className='top-section'>
                <div className='title'>
                    <p className='small-title'>Explore Courses</p>
                    <h2>My Courses</h2>
                </div>
                <div className='show-all-button'>
                    <a href='/classroom/courses'>Show All</a>
                </div>
            </div>
            <div className='courses-list'>
                <div className='courses row'>
                    {
                        Courses?.map((c, i) => {
                            return Languages?.map(lan => {
                                return (
                                    <div className='col-lg-12' key={i}>
                                        <MyCoursesList course={c} language={lan} />
                                    </div>
                                )
                            });
                        })
                    }
                </div>
            </div>
        </div>
    )
}
