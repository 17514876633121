import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    siDarkMode: false,
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        changeTheme: state => {
            state.isDarkMode = !state.isDarkMode
            document.body.classList.toggle('dark-theme-variables')
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeTheme } = themeSlice.actions

export const showTheme = state => state.theme

export default themeSlice.reducer
