import React, { useState } from 'react'
import FsLightbox from 'fslightbox-react'
import { getImageSrc } from '../../../Util/util'

export default function VideoTile({ video, languageId }) {
    const [toggler, setToggler] = useState(false)

    const vidByLanguage = video.VideoByLanguages.filter(vid => vid.LanguageId == languageId)[0]

    console.log(vidByLanguage.videoSrc);
    return (
        <div className='course-item col-md-6 col-sm-12 col-lg-4'>
            <div
                className='course-inner-item'
                onClick={() => setToggler(!toggler)}
            >
                <img src={vidByLanguage.imgSrc ? getImageSrc(vidByLanguage.imgSrc) : getImageSrc(video.imgSrc)}></img>
                <h5>{video.title}</h5>
                <p className='desc'>{video.shortDescription}</p>
            </div>

            <FsLightbox toggler={toggler} sources={[
                <iframe
                    title="video"
                    src={vidByLanguage.videoSrc}
                    width="1920px"
                    height="1080px"
                    allow="autoplay; fullscreen; encrypted-media"
                    allowFullScreen
                />,

            ]} />
        </div>
    )
}
