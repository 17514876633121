import React, { useEffect, useRef, useState } from 'react'

import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import CancelIcon from "@mui/icons-material/Cancel";


import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';


import { useCreateCourseMutation } from '../../../../Redux/Course/CourseAPI'


export default function AddCourse({ changeView }) {
    const addCourseRef = useRef(undefined);

    const [createCourse, { isLoading, error, isSuccess }] = useCreateCourseMutation();


    const [course, setCourse] = useState({
        title: '',
        shortDescription: '',
        longDescription: '',
        isPublic: false
    });

    const [localError, setLocalError] = useState('');


    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleInputChange = (e, key) => {
        const { name, value, type, checked } = e.target;
        setCourse({
            ...course,
            [key]: type === 'checkbox' ? checked : value
        })
    }

    const validate = () => {
        if (course.title === '' || course.shortDescription === '' || course.longDescription === '') {
            return false
        } else {
            return true
        }
    }

    const handleAddCourse = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                createCourse(course)
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in all fields')
        }
    }

    console.log(course)

    return (
        <Box component='form' className='add-course-form card-box' onSubmit={handleAddCourse} ref={addCourseRef}>
            <TextField
                id='course-title'
                label='Title'
                type='text'
                variant='filled'
                required
                value={course.title}
                onChangeCapture={(e) => handleInputChange(e, 'title')}
            ></TextField>
            <TextField
                id='course-short-desc'
                label='Short Description'
                type='text'
                multiline={true}
                minRows={4}
                variant='filled'
                required
                value={course.shortDescription}
                onChangeCapture={(e) => handleInputChange(e, 'shortDescription')}
            ></TextField>
            <TextField
                id='course-log-desc'
                label='Long Description'
                type='text'
                multiline={true}
                minRows={8}
                variant='filled'
                required
                value={course.longDescription}
                onChangeCapture={(e) => handleInputChange(e, 'longDescription')}
            ></TextField>
            <div className="checkbox-container">
                <FormControlLabel
                    control={<Checkbox onChange={(e) => handleInputChange(e, 'isPublic')} checked={course.isPublic} />}
                    label="Course is public"
                    id="courseIsPublic"
                />
            </div>
            <CustomButton buttonName="Add Course" onClick={() => { }} type="submit" />
            <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
            {localError && <p className='error'>{localError}</p>}
            {error?.data?.message && <p className='error'>{error?.data?.message}</p>}

        </Box>
    )
}
