import React, { useEffect, useState } from "react";

import GroudBlackImg from "../../../Assets/about-us-ground_black.png";
import GroudGreyImg from "../../../Assets/about-us-ground_grey.png";
import PapersImg from "../../../Assets/about-us-papers.png";
import TableImg from "../../../Assets/about-us-table.png";
import DecorationImg from "../../../Assets/about-us-decoration.png";
import ProgrammerImg from "../../../Assets/about-us-programmer.png";
import EmpEth from "../../../Assets/about-us-empowering-eth.png";

export default function AboutHead() {
  const [playAnimation, setPlayAnimation] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div id="about-head" className="container py-5">
      <div className="row flex-lg-row-reverse g-5 py-5 ">
        <div
          className={`img-section col-10 col-sm-8 col-lg-9 ${
            playAnimation ? "move-img-programmer" : ""
          } `}
        >
          <img className="img-ground-black" src={GroudBlackImg}></img>
          <img className="img-ground-grey" src={GroudGreyImg}></img>
          <img className="img-papers" src={PapersImg}></img>
          <img className="img-table" src={TableImg}></img>
          <img className="img-decoration" src={DecorationImg}></img>
          <img className="img-programmer" src={ProgrammerImg}></img>
          <img className="img-emp-eth" src={EmpEth}></img>

          <div className="waves">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="text-section col-lg-3">
          <h1>Access An Affordable Education & Pursue Your Passion.</h1>
        </div>
      </div>
    </div>
  );
}
