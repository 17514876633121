import React from 'react'
import AboutHead from './AboutHead'
import Stats from './Stats'
import AboutVideo from './AboutVideo'
// import DirectorMessage from './DirectorMessage'
import Consult from '../../../Components/ClientComponents/Consult'
import Feedback from '../../../Components/ClientComponents/Feedback'

export default function About() {
  return (
    <div id="about" className="">
      <AboutHead />
      <Stats />
      <AboutVideo />
      {/* <DirectorMessage /> */}
      <Consult />
      <Feedback />
    </div>
  );
}
