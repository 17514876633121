import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../BaseQuery";

export const courseCategoryApi = createApi({
    reducerPath: 'courseCategoryApi',
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ['CourseCategory'],
    endpoints: builder => ({
        getCourseCategories: builder.query({
            query: () => ({
                url: 'courseCategory',
                method: 'GET',
            }),
            providesTags: ['CourseCategory'],
        }),
        getCourseCategoryById: builder.query({
            query: id => ({
                url: `courseCategory/${id}`,
                method: 'GET',
            }),
            providesTags: ['CourseCategory'],
        }),
        createCourseCategory: builder.mutation({
            query: data => ({
                url: 'courseCategory',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['CourseCategory'],
        }),
        updateCourseCategory: builder.mutation({
            query: data => ({
                url: `courseCategory/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['CourseCategory'],
        }),
        deleteCourseCategory: builder.mutation({
            query: id => ({
                url: `courseCategory/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['CourseCategory'],
        }),
    }),
});

export const {
    useGetCourseCategoriesQuery,
    useGetCourseCategoryByIdQuery,
    useCreateCourseCategoryMutation,
    useUpdateCourseCategoryMutation,
    useDeleteCourseCategoryMutation,
} = courseCategoryApi;