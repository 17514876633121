import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";

import IconMobileApp from "../../../Assets/Icon-mobile-app.svg";
import IconWebDev from "../../../Assets/Icon-web-development.svg";
import IconDataScience from "../../../Assets/Icon-data-science.svg";

const paths = [
    {
        img: IconMobileApp,
        title: "Mobile App",
        desc: "Learn to create user-friendly and functional applications for smartphones and tablets, exploring both iOS and Android platforms."
    },
    {
        img: IconWebDev,
        title: "Web Dev't",
        desc: "Master the art of building dynamic and interactive websites using various programming languages, frameworks, and tools."
    },
    {
        img: IconDataScience,
        title: "Data Science",
        desc: "Dive into the world of data analysis, machine learning, and statistical modeling to extract valuable insights and make informed decisions."
    },
    {
        img: IconMobileApp,
        title: "Blockchain Development",
        desc: "Explore and gaining skills to develop secure and decentralized applications for various industries."
    },
    {
        img: IconWebDev,
        title: "Software Dev't",
        desc: "Acquire a comprehensive understanding of designing and coding, software applications that meet real-world needs."
    },
    {
        img: IconDataScience,
        title: "Game Dev't",
        desc: "Immerse yourself in the process of designing, developing, and optimizing video games, from concept to playable experiences."
    }
]

export default function LearningPath() {
    return (
        <div id='learning-path' className='container py-5'>
            <div className='title'>
                <p className='small-title'>Learning Paths</p>
                <h2>Fostering a playful & engaging learning environment</h2>
            </div>
            <div>
                <Swiper

                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[FreeMode, Pagination]}
                    className="paths"
                >
                    {
                        paths.map((p, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <a href="/login" className='path-item'>
                                        <div className='header'>
                                            <div className='img-wrapper'>
                                                <img src={p.img}></img>
                                            </div>
                                            <h4>{p.title}</h4>
                                        </div>
                                        <p>{p.desc}</p>
                                        <button>Learn More</button>
                                    </a>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}
