import React, { useEffect, useRef, useState } from 'react';


import PopularCoursesVideo from './PopularCoursesVideo';

import DataPopularCourses from '../../../Data/DataPopularCourses';
import { useSearchParams } from 'react-router-dom';


export default function PopularCourses() {
    console.log(DataPopularCourses);

    const pcRef = useRef(null)

    const [searchParams] = useSearchParams();

    const [filterBy, setFilterBy] = useState(searchParams.get('searchKey') || null);
    const [Data, setData] = useState(DataPopularCourses);


    useEffect(() => {
        if (searchParams.get('searchKey') && searchParams.get('searchKey') !== "") {

            const searchKey = searchParams.get('searchKey');
            //search in every key of the object
            const filteredData = DataPopularCourses.filter((v) => {
                for (let key in v) {
                    if (v[key].toString().toLowerCase().includes(searchKey.toLowerCase())) {
                        return v;
                    }
                }
            })
            setData(filteredData);
            pcRef.current.scrollIntoView();
        } else if (searchParams.get('searchKey') === "") {
            setData(DataPopularCourses)
        }
    }, [searchParams])



    useEffect(() => {
        if (filterBy !== null) {
            setData(DataPopularCourses.filter((v) => {
                if (filterBy === "") {
                    return v;
                } else if (v.category === filterBy) {
                    return v;
                }
            }))
        }
    }, [filterBy])



    const categories = [...new Set(DataPopularCourses.map((v) => v.category))];
    return (
        <div id='popular-courses' className='container py-5' ref={pcRef}>
            <div className='top-section'>
                <div className='title'>
                    <p className='small-title'>Explore Programs</p>
                    <h2>Our Most Popular Class</h2>
                    <p className='desc'>Let's join our famous class, the knowledge provided will definitely be useful for you.</p>
                </div>
                <div className='show-all-button'>
                    <a href='/login'>
                        Show All
                    </a>
                </div>
            </div>
            <div className='courses-list'>
                <div className='courses-filter-wrapper'>
                    <div className='courses-filter'>
                        <button onClick={() => setFilterBy("")}>All Courses</button>
                        {categories.map((fi, i) => {
                            return (
                                <button key={i} onClick={() => setFilterBy(fi)}>{fi}</button>
                            )
                        })}
                    </div>
                </div>
                <div className='courses row'>
                    {
                        Data.map((c, i) => {
                            return <PopularCoursesVideo course={c} key={i} />
                        })
                    }
                </div>
            </div>
        </div>
    )
}
