//Create a coureApi by importing createApi and fetchBaseQuery from @reduxjs/toolkit/query/react and baseQuery from BaseQuery.jsx file. The course object has an image

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../BaseQuery";

export const courseApi = createApi({
    reducerPath: 'courseApi',
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ['Course'],
    endpoints: builder => ({
        getCourses: builder.query({
            query: () => ({
                url: 'course',
                method: 'GET',
            }),
            providesTags: ['Course'],
        }),
        getCourseById: builder.query({
            query: id => ({
                url: `course/${id}`,
                method: 'GET',
            }),
            providesTags: ['Course'],
        }),
        createCourse: builder.mutation({
            query: data => ({
                url: 'course',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Course'],
        }),
        updateCourse: builder.mutation({
            query: data => ({
                url: `course/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Course'],
        }),
        deleteCourse: builder.mutation({
            query: id => ({
                url: `course/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Course'],
        }),
        // create a route to upload image based on course id
        uploadImage: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `upload/course/${id}`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['Course'],
        }),

        //create a route to update course categories for a course, I'll be sending an array of category ids
        updateCourseCategories: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `course/${id}/categories`,
                    method: 'PUT',
                    body: data
                };
            },
            invalidatesTags: ['Course'],
        }),
    }),
});


export const {
    useGetCoursesQuery,
    useGetCourseByIdQuery,
    useCreateCourseMutation,
    useUpdateCourseMutation,
    useDeleteCourseMutation,
    useUploadImageMutation,
    useUpdateCourseCategoriesMutation,
} = courseApi;