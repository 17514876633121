import { configureStore } from '@reduxjs/toolkit'
import { userApi } from './User/UserAPI'
import { languageApi } from './Language/LanguageAPI'
import { courseCategoryApi } from './CourseCategory/CourseCategoryAPI'
import { courseApi } from './Course/CourseAPI'
import { videoApi } from './Video/VideoAPI'
import themeSlice from './Theme/Theme'
import authSlice from './User/AuthSlice'


export const store = configureStore({
    reducer: {
        theme: themeSlice,
        auth: authSlice,
        [userApi.reducerPath]: userApi.reducer,
        [courseCategoryApi.reducerPath]: courseCategoryApi.reducer,
        [courseApi.reducerPath]: courseApi.reducer,
        [languageApi.reducerPath]: languageApi.reducer,
        [videoApi.reducerPath]: videoApi.reducer,

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            [
                userApi.middleware,
                courseCategoryApi.middleware,
                courseApi.middleware,
                languageApi.middleware,
                videoApi.middleware,
            ]
        ),
})
