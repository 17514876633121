import React from 'react'

export default function ContactUsText() {
    return (
        <div id='contact-us-text' className='container py-5'>
            <div className='header'>
                <h2>We're always eager to hear from you!</h2>

                <div className='row '>
                    <div className='col-md-4 d-flex  flex-column '>
                        <p className='title'>Address</p>
                        <p>Cape Verde St<br></br>
                            Salfaz Building - 5th Floor<br></br>
                            Addis Ababa, Ethiopia</p>
                    </div>
                    <div className='col-md-4 d-flex  flex-column '>
                        <p className='title'>Contact</p>
                        <p>Phone: <span>+251 90 946 5857</span></p>
                        <p>Phone: <span>+251 91 099 0147</span></p>
                        <p>Email: <span>info@bitappstech.com</span></p>
                    </div>
                    <div className='col-md-4 d-flex  flex-column '>
                        <p className='title'>Hour of operation</p>
                        <p>Monday - Friday: 08:30 am - 5:30 pm</p>
                        <p>Saturday: 08:30 am - 1:00 pm</p>
                    </div>
                </div>
            </div >
        </div >
    )
}
