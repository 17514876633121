import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../../Assets/logo.png";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import InventorySharpIcon from "@mui/icons-material/InventorySharp";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LogoutIcon from "@mui/icons-material/Logout";

import { routeObject } from "../../../router";


export default function SideNav() {
    let location = useLocation();

    const [activeLink, setActiveLink] = useState(location.pathname);

    let logout = () => {
        localStorage.clear();
        window.location.href = "/Login";
    };

    const renderLinks = () => {

        let linkableRoutes = routeObject.filter((route) => route.isAdmin);

        console.log(linkableRoutes);
        return linkableRoutes[0].children[0].children.filter(route => route.isSideNav).map((route, i) => {
            return (
                <div key={i}>
                    <Link
                        to={route.path}
                        key={i}
                        onClick={() => setActiveLink(route.path)}
                        className={route.path == activeLink ? "active" : ""}
                    >
                        <span>{route.icon}</span>
                        <h3>{route.name}</h3>
                    </Link>
                    {route.children &&
                        route.children.map((child) => {
                            return (
                                <Link
                                    key={child.key}
                                    to={child.path}
                                    onClick={() => setActiveLink(child.path)}
                                    className={`${child.path == activeLink ? "active" : ""} child`}
                                >
                                    <span>{child.icon}</span>
                                    <h3>{child.name}</h3>
                                </Link>
                            );
                        })}
                </div>
            );
        });
    };


    return (
        <aside>
            <div className="top">
                <Link to="/Admin/Dashboard">
                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>
                </Link>
                <div className="close" id="close_button">
                    <CloseSharpIcon />
                </div>
            </div>
            <div className="sidebar">
                {renderLinks()}
                <a href="#" onClick={() => logout()}>
                    <LogoutIcon />
                    <h3>Logout</h3>
                </a>
            </div>
        </aside>
    );
}
