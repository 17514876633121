import React, { useEffect, useState, useRef } from 'react'

import { Box, FormControlLabel, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useGetVideoByIdQuery, useUpdateVideoMutation, useUploadImageMutation } from '../../../../Redux/Video/VideoAPI';
import { useGetCoursesQuery } from '../../../../Redux/Course/CourseAPI';


import { getImageSrc } from '../../../../Util/util'
import VideosByLanguageList from './VideosByLanguageList';
import AddVideo from './AddVideo';
import AddVideosByLanguage from './AddVideosByLanguage';

export default function EditVideo({ editVideoId, changeView }) {
    const editVideoRef = useRef(undefined)

    const [updateVideo, { isLoding, error, isSuccess }] = useUpdateVideoMutation();
    const [uploadImage, { isLoding: imageIsLoading, error: imageError, isSuccess: imageIsSuccess }] = useUploadImageMutation();

    const { data: courseData, error: courseError, isLoading: courseIsLoading } = useGetCoursesQuery();

    const [video, setVideo] = useState(null);

    const [videoImage, setVideoImage] = useState({
        imgSrc: null
    })

    const [imgSrc, setImgSrc] = useState(null)

    const [localError, setLocalError] = useState();

    const { data: videoData, error: videoError, isLoading: videoIsLoading } = useGetVideoByIdQuery(editVideoId);

    useEffect(() => {
        if (videoData) {
            setVideo(videoData)

        }
    }, [videoData]);

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleInputChange = (e, key) => {
        const { name, value } = e.target;
        setVideo({
            ...video,
            [key]: key == "order" ? parseInt(value) : value
        })
    }

    const validate = () => {
        if (video.title === '' || video.order == 0) {
            return false
        } else {
            return true
        }
    }

    const handleUpdateVideo = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                updateVideo({
                    videoId: editVideoId,
                    video: video
                })
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in all fields')
        }
    }

    const handleImageChange = (e) => {
        if (e.target?.files?.length === 0) return;
        const file = e.target.files[0] || e.dataTransfer.files[0];
        setVideoImage({ imgSrc: file });

        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const handleUploadImage = async (e) => {
        e.preventDefault();
        const data = new FormData();

        Object.keys(videoImage).forEach(key => {
            data.append(key, videoImage[key]);
        });

        try {
            uploadImage({
                id: video.id,
                data: data
            });
        } catch (e) {
            console.log(e)
        }
    }

    if (!video) return <h1>Loading...</h1>

    return (
        <div>
            <Box
                component='form'
                className='edit-video-form card-box'
                ref={editVideoRef}
                onSubmit={handleUpdateVideo}
            >
                <div className='course-picker'>
                    <InputLabel >Course</InputLabel>
                    <Select
                        label='Course'
                        name='course'
                        variant='filled'
                        required
                        value={video?.CourseId}
                        onChange={(e) => handleInputChange(e, 'CourseId')}
                    >
                        {courseData?.map((course) => (
                            <MenuItem key={course.id} value={course.id}>
                                {course.title}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <TextField
                    label='Title'
                    name='title'
                    type='text'
                    variant='filled'
                    required
                    value={video.title}
                    onChange={(e) => handleInputChange(e, 'title')}
                />
                <TextField
                    label='Short Description'
                    name='shortDescription'
                    type='text'
                    multiline={true}
                    minRows={4}
                    variant='filled'
                    value={video.shortDescription}
                    onChange={(e) => handleInputChange(e, 'shortDescription')}
                />
                <TextField
                    label='Long Description'
                    name='longDescription'
                    type='text'
                    multiline={true}
                    minRows={4}
                    variant='filled'
                    value={video.longDescription}
                    onChange={(e) => handleInputChange(e, 'longDescription')}
                />
                <TextField
                    label='Overview'
                    name='overview'
                    multiline={true}
                    minRows={4}
                    variant='filled'
                    value={video.overview}
                    onChange={(e) => handleInputChange(e, 'overview')}
                />
                <TextField
                    label='Learning Objectives'
                    name='learningObjectives'
                    multiline={true}
                    minRows={4}
                    variant='filled'
                    value={video.learningObjectives}
                    onChange={(e) => handleInputChange(e, 'learningObjectives')}
                />
                <TextField
                    label='Order'
                    name='order'
                    type='number'
                    variant='filled'
                    required
                    value={video.order}
                    onChange={(e) => handleInputChange(e, 'order')}
                />
                {
                    video?.imgSrc && (<img src={getImageSrc(video.imgSrc)} alt='video' />)
                }

                <CustomButton buttonName="Save Video" onClick={() => { }} type="submit" icon={<SaveIcon />} />
                <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />

                {localError && <p className='error'>{localError}</p>}
                {error?.data?.message && <p className='error'>{error?.data?.message}</p>}
            </Box>

            {/* create a field for an image upload where you can also drag and drop */}
            <Box
                component='form'
                className='edit-video-form upload-image-box card-box'
                onSubmit={handleUploadImage}
            >
                <div className='input-wrapper' onDrop={handleImageChange} onDragOver={(e) => e.preventDefault()}>
                    <input
                        accept='image/*'
                        id='raised-button-file'
                        multiple
                        type='file'
                        onChange={handleImageChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Box display="flex" flexDirection="column" alignItems="center">
                            {
                                imgSrc ? <img src={imgSrc} alt="course" /> :
                                    (<>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <CloudUploadIcon />
                                        </IconButton>
                                        <Typography>Drag and drop files here or click to select files</Typography>
                                    </>)
                            }
                        </Box>
                    </label>
                </div>


                <CustomButton buttonName={!video?.imgSrc ? 'Add Image' : 'Save Image'} onClick={() => { }} type="submit" icon={<SaveIcon />} />
                <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
            </Box >


            <VideosByLanguageList videoId={editVideoId} changeView={changeView} />

            <AddVideosByLanguage videoId={editVideoId} />
        </div>
    )
}
