import React, { useEffect, useRef, useState } from 'react';

import { Box, FormControlLabel, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useAddVideoByLanguageMutation } from '../../../../Redux/Video/VideoAPI';

import { useGetLanguagesQuery } from '../../../../Redux/Language/LanguageAPI';

export default function AddVideosByLanguage({ videoId }) {
    const addVideoByLanguageRef = useRef(undefined);

    const [addVideoByLanguage, { isLoading, error, isSuccess }] = useAddVideoByLanguageMutation();
    const { data: languageData, error: languageError, isLoading: languageIsLoading } = useGetLanguagesQuery();

    const [videoByLanguage, setVideoByLanguage] = useState({
        videoSrc: '',
    });

    const [languageId, setLanguageId] = useState(0)

    const [localError, setLocalError] = useState('');

    const handleInputChange = (e, key) => {
        const { name, value } = e.target;
        setVideoByLanguage({
            ...videoByLanguage,
            [key]: value
        })
    }

    const validate = () => {
        if (languageId == null || languageId === 0 || videoByLanguage.videoSrc === '') {
            return false
        } else {
            return true
        }
    }

    const handleAddVideoByLanguage = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                addVideoByLanguage({
                    videoId: videoId,
                    languageId: languageId,
                    data: videoByLanguage
                })
            } catch (error) {
                setLocalError(error)
            }
        } else {
            setLocalError('Please fill out all fields')
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setVideoByLanguage({
                videoSrc: ''
            })
        }
    }, [isSuccess])

    const handleCancel = () => {
        setVideoByLanguage({
            videoSrc: ''
        })

        setLanguageId(0);
    }

    if (!languageData) return <h1>Loading...</h1>

    return (
        <Box
            component='form'
            className='add-video-by-language card-box'
            ref={addVideoByLanguageRef}
            onSubmit={handleAddVideoByLanguage}
        >
            <h1>Add Videos by Language</h1>
            <FormControl>
                <InputLabel id='language-label'>Language</InputLabel>
                <Select
                    labelId='language-label'
                    id='language'
                    variant='filled'
                    required
                    value={languageId}
                    label='Language'
                    onChange={(e) => setLanguageId(e.target.value)}
                >
                    {languageData.map(language => (
                        <MenuItem key={language.id} value={language.id}>{language.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                id='videoSrc'
                name='videoSrc'
                label='Video Source'
                variant='outlined'
                value={videoByLanguage.videoSrc}
                onChange={(e) => handleInputChange(e, 'videoSrc')}
            />
            <CustomButton
                buttonName='Add Video By Language'
                type='submit'
                onClick={() => { }}
            />
            <CustomButton buttonName="Cancel" onClick={() => handleCancel()} icon={<CancelIcon />} classname="danger" type="button"
            />

            {localError && <p>{localError}</p>}
            {error?.data?.message && <p>{error?.data?.message}</p>}
        </Box>
    )
}