import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import EnhancedTableHead, { getComparator, stableSort } from '../../../../Components/AdminComponents/EnhancedTable/EnhancedTable';

import { useDeleteCourseMutation } from '../../../../Redux/Course/CourseAPI';

const headCells = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
    },
    {
        id: "shortDescription",
        numeric: false,
        disablePadding: false,
        label: "Short Description",
    },
    {
        id: "longDescription",
        numeric: false,
        disablePadding: false,
        label: "Long Description",
    },
    {
        id: "isPublic",
        numeric: false,
        disablePadding: false,
        label: "Public",
    },
    {
        id: 'rating',
        numeric: true,
        disablePadding: false,
        label: 'Rating'
    }
];


export default function CourseList({ courses, changeView, setEditCourseId }) {

    const navigate = useNavigate();

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("title");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [deleteCourse, { isLoading, error, isSuccess }] = useDeleteCourseMutation();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = courses.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleEditCourse = (id) => {
        setEditCourseId(id);
        changeView();
    };

    const handleDeleteCourse = (id) => {
        //alert user to confirm delete
        let response = window.confirm("Are you sure you want to delete this course?");
        if (response) deleteCourse(id);
    };

    const handleClick = (event, id) => {

        navigate(`/dashboard/packages/${id}`);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - courses.length) : 0;

    if (courses.length === 0) return <h1>No courses available</h1>

    return (

        <Box sx={{ width: "100%" }} className="course-table mui-table">
            <div>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={courses.length}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(courses, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell component="td" id={labelId} scope="row" padding="none">
                                                {row.title}
                                            </TableCell>
                                            <TableCell align="left">{row.shortDescription}</TableCell>
                                            <TableCell align="left">{row.longDescription}</TableCell>
                                            <TableCell align="left">{row.isPublic ? "Yes" : "No"}</TableCell>
                                            <TableCell align="right">{row.rating}</TableCell>

                                            {/* create an edit button */}
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => handleEditCourse(row.id)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>

                                            {/* create a delete button */}
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteCourse(row.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={courses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        </Box>

    );
}
