// Create a course page that lists a list of videos for a course in a specific language. Get the course id and the language id from the url. Use the useGetVideosByCourseAndLanguageQuery hook to get the videos for the course and language. Sort the videos by order and display the first all videos. Create a renderVid function that returns a list of VideoTile components. If the videos are still loading, display a loading message. Display the course title, language name, course categories, and short description. Use the renderVid function to display the videos. Export the MyCoursesList component.

import React, { useEffect, useState } from "react";

import VideoTile from "../../../Components/ClassroomComponents/VideoTile/VideoTile";

import { useGetVideosByCourseAndLanguageQuery } from "../../../Redux/Video/VideoAPI";
import { useGetCourseByIdQuery } from "../../../Redux/Course/CourseAPI";
import { useGetLanguageByIdQuery } from "../../../Redux/Language/LanguageAPI";
import { useParams } from "react-router-dom";

export default function CoursePage() {

    //get the course id and language id from the path by using react-router-dom
    const { courseId, languageId } = useParams();

    //get course data by course id
    const { data: course, error: courseError, isLoading: courseLoading } = useGetCourseByIdQuery(courseId);

    //get language data by language id
    const { data: language, error: languageError, isLoading: languageLoading } = useGetLanguageByIdQuery(languageId);


    const { data: Videos, error: videoError, isLoading: videoLoading } = useGetVideosByCourseAndLanguageQuery({ courseId: courseId, languageId: languageId });

    const [videoList, setVideoList] = useState([])

    useEffect(() => {
        if (Videos) {
            setVideoList([...Videos].sort((a, b) => a.order - b.order))
        }
    }, [Videos])



    var renderVid = () => {
        //sort video by order
        if (videoList) {

            return (videoList.map((video, i) => {
                return (
                    <VideoTile video={video} languageId={languageId} key={i} />
                )
            })
            )
        }
    }

    if (courseLoading || languageLoading || videoLoading) {
        return (<div> Loading... </div>)
    }

    return (
        <div className='course'>
            <div className=''>
                <h4>{course.title} - {language.name}</h4>
                <div className='course-category'>
                    <p>{course.CourseCategories.map((cc, i) => {
                        if (i === course.CourseCategories.length - 1) {
                            return cc.categoryName
                        } else {
                            return cc.categoryName + ', '
                        }
                    })}</p>
                </div>
            </div>
            <div className='course-content'>
                <p>{course.shortDescription}</p>
                <div className=' row'>
                    {
                        renderVid()
                    }
                </div>
            </div>
        </div>
    )
}