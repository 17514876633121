//create an edit language component
import React, { useState, useEffect, useRef } from 'react';

import { Box, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useUpdateLanguageMutation, useGetLanguageByIdQuery } from '../../../../Redux/Language/LanguageAPI';

export default function EditLanguage({ editLanguageId, changeView }) {
    const editLanguageRef = useRef(undefined);

    const [updateLanguage, { isLoding, error, isSuccess }] = useUpdateLanguageMutation();

    const [language, setLanguage] = useState({
        name: ''
    });

    const [localError, setLocalError] = useState('');

    const { data: languageData, error: languageError, isLoading: languageIsLoading } = useGetLanguageByIdQuery(editLanguageId);

    useEffect(() => {
        if (languageData) {
            setLanguage(languageData)
        }
    }, [languageData])

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleChange = (e, key) => {
        setLanguage({
            ...language,
            [key]: e.target.value
        })
    }

    const validate = () => {
        if (language.name === '') {
            return false
        } else {
            return true
        }
    }

    const handleEditLanguage = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                updateLanguage(language)
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in the name field')
        }
    }

    return (
        <Box
            component='form'
            ref={editLanguageRef}
            onSubmit={handleEditLanguage}
            className='edit-language-form card-box'
        >
            <TextField
                fullWidth
                label='Name'
                variant='outlined'
                value={language.name}
                onChange={(e) => handleChange(e, 'name')}
            />
            <CustomButton
                buttonName='Edit Language'
                type='submit'
                onClick={() => { }}
            />
            <CustomButton
                buttonName='Cancel'
                onClick={changeView}
                icon={<CancelIcon />}
                classname='danger'
            />

            {localError && <p>{localError}</p>}
            {error?.data?.message && <p>{error?.data?.message}</p>}
        </Box>
    )
}