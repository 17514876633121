//Create a videoApi by importing createApi and fetchBaseQuery from @reduxjs/toolkit/query/react and baseQuery from BaseQuery.jsx file. The video object has an image

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../BaseQuery";

export const videoApi = createApi({
    reducerPath: 'videoApi',
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ['Video'],
    endpoints: builder => ({
        getVideos: builder.query({
            query: () => ({
                url: 'video',
                method: 'GET',
            }),
            providesTags: ['Video'],
        }),
        getVideoById: builder.query({
            query: id => ({
                url: `video/${id}`,
                method: 'GET',
            }),
            providesTags: ['Video'],
        }),
        createVideo: builder.mutation({
            query: ({ courseId, video }) => ({
                url: `video/${courseId}`,
                method: 'POST',
                body: video
            }),
            invalidatesTags: ['Video'],
        }),
        updateVideo: builder.mutation({
            query: data => ({
                url: `video/${data.videoId}`,
                method: 'PUT',
                body: data.video,
            }),
            invalidatesTags: ['Video'],
        }),
        deleteVideo: builder.mutation({
            query: id => ({
                url: `video/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Video'],
        }),
        // create a route to upload image based on video id
        uploadImage: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `upload/video/${id}`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['Video'],
        }),

        addVideoByLanguage: builder.mutation({
            query: ({ videoId, languageId, data }) => {
                return {
                    url: `video/addVideoByLanguage/${videoId}/${languageId}`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['Video'],
        }),
        updateVideoByLanguageById: builder.mutation({
            query: ({ videoByLanguageId, data }) => {
                return {
                    url: `video/updateVideoByLanguage/${videoByLanguageId}`,
                    method: 'PUT',
                    body: data
                };
            },
            invalidatesTags: ['Video'],
        }),

        updateVideoByLanguage: builder.mutation({
            query: ({ videoId, languageId, data }) => {
                return {
                    url: `video/updateVideoByLanguage/${videoId}/${languageId}`,
                    method: 'PUT',
                    body: data
                };
            },
            invalidatesTags: ['Video'],
        }),
        deleteVideoByLanguage: builder.mutation({
            query: ({ videoId, languageId }) => {
                return {
                    url: `video/deleteVideoByLanguage/${videoId}/${languageId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['Video'],
        }),
        getVideoByLanguage: builder.query({
            query: ({ videoId, languageId }) => {
                return {
                    url: `video/getVideoByLanguage/${videoId}/${languageId}`,
                    method: 'GET',
                };
            },
            providesTags: ['Video'],
        }),
        getAllVideosByVideoId: builder.query({
            query: videoId => {
                return {
                    url: `video/getAllVideosByVideoId/${videoId}`,
                    method: 'GET',
                };
            },
            providesTags: ['Video'],
        }),
        getAllVideoByLanguage: builder.query({
            query: () => {
                return {
                    url: `video/getAllVideoByLanguage`,
                    method: 'GET',
                };
            },
            providesTags: ['Video'],
        }),
        getVideoByLanguageId: builder.query({
            query: languageId => {
                return {
                    url: `video/getVideoByLanguageId/${languageId}`,
                    method: 'GET',
                };
            },
            providesTags: ['Video'],
        }),
        updateVideoByLanguageImage: builder.mutation({
            query: ({ videoByLanguageId, data }) => {
                return {
                    url: `upload/video/videoByLanguage/${videoByLanguageId}`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['Video'],
        }),
        getVideosByCourseAndLanguage: builder.query({
            query: ({ courseId, languageId }) => {
                return {
                    url: `video/getVideosByCourseAndLanguage/${courseId}/${languageId}`,
                    method: 'GET',
                };
            },
            providesTags: ['Video'],
        }),
    }),
});

export const {
    useGetVideosQuery,
    useGetVideoByIdQuery,
    useCreateVideoMutation,
    useUpdateVideoMutation,
    useDeleteVideoMutation,
    useUploadImageMutation,
    useAddVideoByLanguageMutation,
    useUpdateVideoByLanguageMutation,
    useDeleteVideoByLanguageMutation,
    useGetVideoByLanguageQuery,
    useGetAllVideosByVideoIdQuery,
    useGetAllVideoByLanguageQuery,
    useGetVideoByLanguageIdQuery,
    useUpdateVideoByLanguageByIdMutation,
    useUpdateVideoByLanguageImageMutation,
    useGetVideosByCourseAndLanguageQuery

} = videoApi;