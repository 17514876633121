import React, { useEffect, useState } from 'react'
import FsLightbox from "fslightbox-react";

import CoinImg from "../../../Assets/about-us-video-coin.png";
import GuyImg from "../../../Assets/about-video-guy.png";
import CoinsImg from "../../../Assets/about-video-coins.png";
import BgImg from "../../../Assets/about-video-bg.png";


export default function AboutVideo() {
    const [toggler, setToggler] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <div id='about-video' className='container py-5'>
            <div className='row flex-lg-row-reverse g-5 py-5 align-items-center '>
                <div className={`img-section col-sm-12 col-lg-6 ${playAnimation ? "move-img-rich-guy" : ""} `}  >
                    <img className='img-coin' src={CoinImg}></img>
                    <img className='img-guy' src={GuyImg}></img>
                    <img className='img-ground-coins' src={CoinsImg}></img>
                    <img className='img-bg' src={BgImg}></img>
                </div>
                <div className='text-section col-lg-6'>
                    <h3>Improving Lives <span> Though Learning</span>
                    </h3>
                    <button onClick={() => setToggler(!toggler)}>
                        Play Video
                    </button>

                    <FsLightbox
                        toggler={toggler}
                        sources={[
                            <iframe title="vimeo-player" src="https://player.vimeo.com/video/874172371?h=017b47461d" width="940" height="560" frameborder="0" allowfullscreen></iframe>,
                        ]}
                    />

                </div>
            </div>
        </div>
    )
}
