import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router'

import { ThemeProvider, createTheme } from '@mui/material'
import { showTheme } from '../../Redux/Theme/Theme'
import { useSelector } from 'react-redux'

export default function AdminRoot() {
    const navigate = useNavigate();

    useEffect(() => {
        const loginStatus = localStorage.getItem('login_status');
        const user = JSON.parse(localStorage.getItem('user_info'));
        if (loginStatus !== 'User has logged in.') {
            navigate('/login')
        } else if (!(user.phoneNumber.includes('909465857') || user.phoneNumber.includes('910990147'))) {
            navigate('/login')
        }
    }, [])


    const theme = useSelector(showTheme)

    let muitheme = createTheme({
        pallette: {
            mode: theme.isDarkMode ? 'dark' : 'light',
        },
    })

    return (
        <ThemeProvider theme={muitheme}>
            <div id='admin-dashboard'>
                <Outlet />
            </div>
        </ThemeProvider>
    )
}
