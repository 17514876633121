import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'

import SpeakImg from "../../../Assets/Speak.svg";
import BreifCaseImg from "../../../Assets/Briefcase.svg";
import IdeaImg from "../../../Assets/idea.svg";
import ProgrammerImg from "../../../Assets/intro-programmer.png";
import ProgrammerBackgroundImg from "../../../Assets/intro-programmer-bg-1.png";
import DataOnlineCourseImg from "../../../Assets/data-online-courses.png";
import DataTutorsImg from "../../../Assets/data-tutor.png";

export default function Intro() {

    const [searchParams, setSearchParams] = useSearchParams()
    const [searchInput, setSearchInput] = useState(searchParams.get('searchKey') || '')


    const [playAnimation, setPlayAnimation] = useState(false);

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(true);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    const setSearchKey = (e) => {
        e.preventDefault();
        setSearchParams({ 'searchKey': searchInput })
    }


    console.log(searchInput);

    return (
        <div id='intro' className='container py-5'>
            <div className='row flex-lg-row-reverse align-items-center g-5 py-5'>

                <div className={`img-section col-sm-12 col-lg-6 ${playAnimation ? "move-img-programmer" : ""} `}  >
                    <img className='img-programmer-bg' src={ProgrammerBackgroundImg}></img>
                    <img className='img-programmer' src={ProgrammerImg}></img>
                    <img className='img-data-courses' src={DataOnlineCourseImg}></img>
                    <img className='img-data-tutor' src={DataTutorsImg}></img>

                    <div className='waves'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className='text-section col-lg-6'>
                    <h1>Up your <span className='span-1'>skills</span> to <span className='span-2'>advance</span> your <span className='span-3'>career</span> path</h1>
                    <p>
                        Provides you with the latest online learning system and material that help your knowledge growing.
                    </p>
                    <form className='search-box' onSubmit={(e) => setSearchKey(e)}>
                        <input placeholder='What would you like to learn?' value={searchInput} onChange={(e) => setSearchInput(e.target.value)}></input>
                        <button onClick={(e) => setSearchKey(e)}>Search</button>
                    </form>
                    <div className='objectives'>
                        <ul>
                            <li>
                                <img src={SpeakImg}></img>
                                <p>Great Leader</p>
                            </li>
                            <li>
                                <img src={BreifCaseImg}></img>
                                <p>Career-Oriented</p>
                            </li>
                            <li>
                                <img src={IdeaImg}></img>
                                <p>Creative Thinking</p>
                            </li>
                        </ul>
                    </div>


                    <div className='waves'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>

        </div>
    )
}
