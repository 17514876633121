import React from "react";

import consultLadyImg from "../../Assets/consult-lady1.png";

export default function Consult() {
  return (
    <div id="consult">
      <div className="consult-content container py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="img-section col-sm-8 col-lg-6 ">
            <img src={consultLadyImg}></img>
          </div>
          <div className="text-section col-lg-6">
            <h3 className="newtext__header">
              Still confused about your career choice? consult with our experts
            </h3>
            <div className="show-all-button">
              <a href="/contactUs">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
