import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import baseQuery from '../BaseQuery'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ['User', 'Auth'],
    endpoints: builder => ({
        adminLogin: builder.mutation({
            query: data => ({
                url: 'auth/adminLogin',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Auth'],
        }),
        login: builder.mutation({
            query: data => ({
                url: 'auth/login',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Auth'],
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'auth/logout',
                method: 'POST',
            }),
            invalidatesTags: ['Auth'],
        }),
        getUser: builder.query({
            query: id => ({
                url: `auth/user/${id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        updateUser: builder.mutation({
            query: data => ({
                url: 'auth/user',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        changePassword: builder.mutation({
            query: data => ({
                url: 'auth/change-password',
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        forgotPassword: builder.mutation({
            query: data => ({
                url: 'auth/forgotPassword',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
        resetPassword: builder.mutation({
            query: data => ({
                url: 'auth/resetPassword',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useGetUserQuery,
    useUpdateUserMutation,
    useChangePasswordMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
} = userApi
