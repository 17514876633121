import React, { useEffect, useState } from 'react'
import { Form, useNavigate } from "react-router-dom";

import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ViewIcon from "@mui/icons-material/Visibility";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";

import FsLightbox from "fslightbox-react";

import EnhancedTableHead, { getComparator, stableSort } from '../../../../Components/AdminComponents/EnhancedTable/EnhancedTable';

import { useDeleteVideoByLanguageMutation, useGetAllVideosByVideoIdQuery, useUpdateVideoByLanguageImageMutation, useUpdateVideoByLanguageByIdMutation } from '../../../../Redux/Video/VideoAPI';
import { useGetLanguagesQuery } from '../../../../Redux/Language/LanguageAPI';
import { getImageSrc } from '../../../../Util/util';


const headCells = [
    {
        id: "videoSrc",
        numeric: false,
        disablePadding: true,
        label: "Video Source",
    },
    {
        id: "language",
        numeric: false,
        disablePadding: false,
        label: "Language",
    },
    {
        id: "imageSrc",
        numeric: false,
        disablePadding: false,
        label: "Image Source",
    },
    {
        id: "rating",
        numeric: false,
        disablePadding: false,
        label: "Rating",
    }
];

export default function VideosByLanguageList({ videoId, changeView }) {
    const navigate = useNavigate();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("videoSrc");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [videoViewToggle, setVideoViewToggle] = useState(false);

    const [videosByLanguage, setVideosByLanguage] = useState([]);
    const [currentVideoLink, setCurrentVideoLink] = useState("")

    const [editVideoByLanguageId, setEditVideoByLanguageId] = useState(0);
    const [editVideoByLanguageObject, setEditVideoByLanguageObject] = useState({});

    const [videoByLanguageImage, setVideoByLanguageImage] = useState({
        imgSrc: null
    })

    const { data: videoByLanguageData, error: videoError, isLoading: videoLoading } = useGetAllVideosByVideoIdQuery(videoId);

    const { data: languageData, error: languageError, isLoading: languageIsLoading } = useGetLanguagesQuery();

    const [updateVideoByLanguageById, { isLoading: updateVideoByLanguageByIdLoading, error: updateVideoByLanguageByIdError, isSuccess: updateVideoByLanguageByIdSuccess }] = useUpdateVideoByLanguageByIdMutation();
    const [uploadImage, { isLoading: uploadImageLoading, error: uploadImageError, isSuccess: uploadImageSuccess }] = useUpdateVideoByLanguageImageMutation();
    const [deleteVideoByLanguage, { isLoading: deletevideoByLanguageLoading, error: deleteVideoByLanguageError, isSuccess: deleteVideoByLanguageSuccess }] = useDeleteVideoByLanguageMutation();


    useEffect(() => {
        if (videoByLanguageImage.imgSrc) {
            if (updateVideoByLanguageByIdSuccess && uploadImageSuccess) {
                setEditVideoByLanguageId(0);
                setEditVideoByLanguageObject({});
            }
        } else {
            if (updateVideoByLanguageByIdSuccess) {
                setEditVideoByLanguageId(0);
                setEditVideoByLanguageObject({});
            }
        }
    }, [updateVideoByLanguageByIdSuccess, uploadImageSuccess]);


    useEffect(() => {
        if (videoByLanguageData) {
            setVideosByLanguage(videoByLanguageData);
        }
    }, [videoByLanguageData]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = videosByLanguage.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, videosByLanguage.length - page * rowsPerPage);

    const handleDeleteVideoByLanguage = (vidId, languageId) => {
        //alert user to confirm delete
        let response = window.confirm("Are you sure you want to delete this video?");
        if (response) deleteVideoByLanguage({
            videoId: vidId,
            languageId: languageId
        });
    }

    const handleVideoView = (videoSrc) => {
        setCurrentVideoLink(videoSrc);
        setVideoViewToggle(!videoViewToggle);
    }

    const handleImageChange = (e) => {
        if (e.target?.files?.length === 0) return;
        const file = e.target.files[0] || e.dataTransfer.files[0];
        setVideoByLanguageImage({ imgSrc: file });
    }

    const handleEditVideoByLanguageId = (id) => {
        setEditVideoByLanguageId(id);
        setEditVideoByLanguageObject(videosByLanguage.find(video => video.id === id));
    }

    const validate = () => {
        if (editVideoByLanguageObject.videoSrc === "" || editVideoByLanguageObject.LanguageId === "") {
            return false;
        }
        return true;
    }

    const handleInputChange = (e, name) => {
        setEditVideoByLanguageObject({
            ...editVideoByLanguageObject,
            [name]: e.target.value
        })
    }

    const handleUpdateVideoByLanguage = () => {
        if (!validate()) return;

        updateVideoByLanguageById({
            videoByLanguageId: editVideoByLanguageId,
            data: editVideoByLanguageObject
        });

        if (videoByLanguageImage.imgSrc) {
            handleUploadImage();
        }

    }

    const handleUploadImage = async () => {
        const data = new FormData();

        Object.keys(videoByLanguageImage).forEach(key => {
            data.append(key, videoByLanguageImage[key]);
        });

        try {
            uploadImage({
                videoByLanguageId: editVideoByLanguageObject.id,
                data: data
            });
        } catch (e) {
            console.log(e)
        }
    }

    const cancelEdit = () => {
        setEditVideoByLanguageId(0);
        setEditVideoByLanguageObject({});
    }

    console.log(editVideoByLanguageId)

    console.log(videosByLanguage)

    if (videoLoading) return <div>Loading...</div>;

    if (videosByLanguage.length === 0) return <div>No Videos Found</div>;

    return (
        <Box className="video-by-language-table mui-table">
            <div>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={videosByLanguage.length}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {stableSort(videosByLanguage, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((video, index) => {
                                    const isItemSelected = isSelected(video.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    if (editVideoByLanguageId === video.id) return (
                                        <TableRow
                                            hover
                                            key={video.id}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ wordBreak: "break-all" }}>
                                                <TextField
                                                    id='videoSrc'
                                                    name='videoSrc'
                                                    label='Video Source'
                                                    variant='outlined'
                                                    value={editVideoByLanguageObject.videoSrc}
                                                    onChange={(e) => handleInputChange(e, 'videoSrc')}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <InputLabel id='language-label'>Language</InputLabel>
                                                    <Select
                                                        labelId='language-label'
                                                        id='language'
                                                        variant='filled'
                                                        required
                                                        value={editVideoByLanguageObject.LanguageId}
                                                        label='Language'
                                                        onChange={(e) => handleInputChange(e, 'LanguageId')}
                                                    >
                                                        {languageData.map(language => (
                                                            <MenuItem key={language.id} value={language.id}>{language.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <input
                                                    accept='image/*'
                                                    id='raised-button-file'
                                                    multiple
                                                    type='file'
                                                    onChange={handleImageChange}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{editVideoByLanguageObject.rating}</TableCell>
                                            <TableCell align="left">
                                                <IconButton
                                                    aria-label="view"
                                                    onClick={() => handleUpdateVideoByLanguage()}
                                                >
                                                    <SaveIcon style={{ color: "#7380ec" }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="view"
                                                    onClick={() => cancelEdit()}
                                                >
                                                    <CancelIcon className="danger" />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>

                                    )

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, video.id)}
                                            role="checkbox"

                                            tabIndex={-1}
                                            key={video.id}


                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ wordBreak: "break-all" }}>
                                                {video.videoSrc}
                                            </TableCell>
                                            <TableCell align="left">{video.Language.name}</TableCell>
                                            <TableCell align="left">{video.imgSrc &&
                                                <img src={getImageSrc(video.imgSrc)} style={{ maxWidth: "400px" }} />
                                            }</TableCell>
                                            <TableCell align="left">{video.rating}</TableCell>
                                            <TableCell align="left">
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => handleEditVideoByLanguageId(video.id)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteVideoByLanguage(videoId, video.Language.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="view"
                                                    onClick={() => handleVideoView(video.videoSrc)}
                                                >
                                                    <ViewIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={videosByLanguage.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />

            {
                currentVideoLink &&
                <FsLightbox
                    toggler={videoViewToggle}
                    sources={[
                        <iframe
                            title="video"
                            src={currentVideoLink}
                            width="1920px"
                            height="1080px"
                            allow="autoplay; fullscreen; encrypted-media"
                            allowFullScreen
                        />,

                    ]}
                />
            }
        </Box>
    )
}