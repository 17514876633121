import React, { useState } from 'react'
import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton'
import AddVideo from './AddVideo'
import VideoList from './VideoList'
import EditVideo from './EditVideo'

import { useGetVideosQuery } from '../../../../Redux/Video/VideoAPI'

export default function Videos() {

    const [addVideoView, setAddVideoView] = useState(false)
    const [editVideoView, setEditVideoView] = useState(false)
    const [editVideoId, setEditVideoId] = useState(null)

    const { data: videoData, error, isLoading } = useGetVideosQuery()

    if (isLoading) return <h1>Loading...</h1>

    return (
        <div className='video-container'>
            <h1>Videos</h1>
            {!addVideoView && !editVideoView && (
                <CustomButton
                    buttonName={'Add Video'}
                    onClick={() => setAddVideoView(!addVideoView)}
                />
            )}
            {addVideoView && (
                <AddVideo
                    changeView={() => setAddVideoView(!addVideoView)}
                />
            )}
            {editVideoView && (
                <EditVideo
                    editVideoId={editVideoId}
                    changeView={() => setEditVideoView(!editVideoView)}
                />
            )}
            {!addVideoView && !editVideoView && (
                <VideoList
                    videos={videoData}
                    changeView={() => setEditVideoView(!editVideoView)}
                    setEditVideoId={setEditVideoId}
                />
            )}
        </div>
    )
}

