//Create a language api by importing createApi and fetchBaseQuery from @reduxjs/toolkit/query/react and baseQuery from BaseQuery.jsx file. The language object has a flag

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseQuery from "../BaseQuery";

export const languageApi = createApi({
    reducerPath: 'languageApi',
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ['Language'],
    endpoints: builder => ({
        getLanguages: builder.query({
            query: () => ({
                url: 'language',
                method: 'GET',
            }),
            providesTags: ['Language'],
        }),
        getLanguageById: builder.query({
            query: id => ({
                url: `language/${id}`,
                method: 'GET',
            }),
            providesTags: ['Language'],
        }),
        createLanguage: builder.mutation({
            query: data => ({
                url: 'language',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Language'],
        }),
        updateLanguage: builder.mutation({
            query: data => ({
                url: `language/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['Language'],
        }),
        deleteLanguage: builder.mutation({
            query: id => ({
                url: `language/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Language'],
        }),
    }),
});

export const {
    useGetLanguagesQuery,
    useGetLanguageByIdQuery,
    useCreateLanguageMutation,
    useUpdateLanguageMutation,
    useDeleteLanguageMutation,
} = languageApi;