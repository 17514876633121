import React from 'react'
import Intro from './Intro'
import LearningPath from './LearningPath'
import PopularCourses from './PopularCourses'
import Consult from '../../../Components/ClientComponents/Consult'
import Feedback from '../../../Components/ClientComponents/Feedback'

export default function Home() {
  return (
    <div id="home">
      <Intro />
      <LearningPath />
      {/* <NewPopulare /> */}
      <PopularCourses />
      <Consult />
      <Feedback />
    </div>
  );
}
