//add video component here. The form fields are title, shortDescription, longDescription, overview, learningObjectives and order of the video.

import React, { useEffect, useRef, useState } from 'react';

import { Box, FormControlLabel, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useCreateVideoMutation } from '../../../../Redux/Video/VideoAPI';

import { useGetCoursesQuery } from '../../../../Redux/Course/CourseAPI';

export default function AddVideo({ changeView }) {
    const addVideoRef = useRef(undefined);

    const [createVideo, { isLoading, error, isSuccess }] = useCreateVideoMutation();
    const { data: courseData, error: courseError, isLoading: courseIsLoading } = useGetCoursesQuery();

    const [video, setVideo] = useState({
        title: '',
        shortDescription: '',
        longDescription: '',
        overview: '',
        learningObjectives: '',
        order: 0
    });

    const [courseId, setCourseId] = useState(0)

    const [localError, setLocalError] = useState('');

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleInputChange = (e, key) => {
        const { name, value } = e.target;
        setVideo({
            ...video,
            [key]: key == 'order' ? parseInt(value) : value
        })
    }

    const validate = () => {
        if (courseId == null || courseId === 0 || video.title === '' || video.order <= 0) {
            return false
        } else {
            return true
        }
    }

    const handleAddVideo = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                createVideo({
                    courseId: courseId,
                    video: video
                })
            } catch (e) {
                console.log(e)
            }
        } else {
            setLocalError('Please fill in all fields')
        }
    }

    console.log(courseId, video)

    if (!courseData) return <h1>Loading...</h1>


    return (
        <Box
            component='form'
            className='add-video-form card-box'
            ref={addVideoRef}
            onSubmit={handleAddVideo}
        >
            {
                (courseId === null || courseId === 0) && (
                    <div className='course-picker'>
                        <InputLabel >Course</InputLabel>
                        <Select
                            label='Course'
                            name='course'
                            variant='filled'
                            required
                            value={courseId}
                            onChange={(e) => setCourseId(e.target.value)}
                        >
                            {courseData.map((course) => (
                                <MenuItem key={course.id} value={course.id}>
                                    {course.title}
                                </MenuItem>
                            ))}
                        </Select>

                        <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />
                    </div>
                )
            }

            {
                (courseId !== null && courseId !== 0) && (

                    (<>
                        <div className='course-picker'>
                            <InputLabel >Course</InputLabel>
                            <Select
                                label='Course'
                                name='course'

                                variant='filled'
                                required
                                value={courseId}
                                onChange={(e) => setCourseId(e.target.value)}
                            >
                                {courseData.map((course) => (
                                    <MenuItem key={course.id} value={course.id}>
                                        {course.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <TextField
                            label='Title'
                            name='title'
                            type='text'
                            variant='filled'
                            required
                            value={video.title}
                            onChange={(e) => handleInputChange(e, 'title')}
                        />
                        <TextField
                            label='Short Description'
                            name='shortDescription'
                            type='text'
                            multiline={true}
                            minRows={4}
                            variant='filled'
                            value={video.shortDescription}
                            onChange={(e) => handleInputChange(e, 'shortDescription')}
                        />
                        <TextField
                            label='Long Description'
                            name='longDescription'
                            type='text'
                            multiline={true}
                            minRows={4}
                            variant='filled'
                            value={video.longDescription}
                            onChange={(e) => handleInputChange(e, 'longDescription')}
                        />
                        <TextField
                            label='Overview'
                            name='overview'
                            multiline={true}
                            minRows={4}
                            variant='filled'
                            value={video.overview}
                            onChange={(e) => handleInputChange(e, 'overview')}
                        />
                        <TextField
                            label='Learning Objectives'
                            name='learningObjectives'
                            multiline={true}
                            minRows={4}
                            variant='filled'
                            value={video.learningObjectives}
                            onChange={(e) => handleInputChange(e, 'learningObjectives')}
                        />
                        <TextField
                            label='Order'
                            name='order'
                            type='number'
                            variant='filled'
                            required
                            value={video.order}
                            onChange={(e) => handleInputChange(e, 'order')}
                        />
                        <CustomButton buttonName={'Add Video'} type='submit' onClick={() => { }} />
                        <CustomButton buttonName="Cancel" onClick={() => changeView()} icon={<CancelIcon />} classname="danger" />

                        {localError && <p className='error'>{localError}</p>}
                        {error?.data?.message && <p className='error'>{error?.data?.message}</p>}
                    </>)
                )
            }
        </Box>
    )
}