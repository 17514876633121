import React, { useEffect, useState, useRef } from 'react'

import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton';

import { useCreateCourseCategoryMutation } from '../../../../Redux/CourseCategory/CourseCategoryAPI';

export default function AddCourseCategory({ changeView }) {
    const addCourseCategoryRef = useRef(undefined)

    const [createCourseCategory, { isLoding, error, isSuccess }] = useCreateCourseCategoryMutation();

    const [category, setCategory] = useState({
        name: '',
        description: ''
    });

    const [localError, setLocalError] = useState('');

    useEffect(() => {
        if (isSuccess) {
            changeView()
        }
    }, [isSuccess])

    const handleChange = (e, key) => {
        setCategory({
            ...category,
            [key]: e.target.value
        })
    }

    //validate if name is empty or not
    const validate = () => {
        if (category.name === '') {
            return false
        } else {
            return true
        }
    }

    const handleAddCourseCategory = (e) => {
        e.preventDefault()
        if (validate()) {
            try {
                createCourseCategory(category)
            } catch (e) {
                console.log(e)
            }

        } else {
            setLocalError('Please fill in the name field')
        }
    }

    return (
        <Box
            component='form'
            className='add-course-form card-box'
            onSubmit={handleAddCourseCategory}
            ref={addCourseCategoryRef}
        >
            <TextField
                id='course-category-name'
                label='Category Name'
                type='text'
                variant='filled'
                required
                onChangeCapture={(e) => handleChange(e, "name")}
            ></TextField>
            <TextField
                id='course-category-desc'
                label='Description'
                type='text'
                multiline={true}
                minRows={4}
                variant='filled'
                onChangeCapture={(e) => handleChange(e, "description")}
            ></TextField>
            <CustomButton
                buttonName='Add Course Cateogry'
                onClick={() => { }}
                type='submit'
            />
            <CustomButton
                buttonName='Cancel'
                onClick={changeView}
                icon={<CancelIcon />}
                classname='danger'
            />
            {localError && <p className='error'>{localError}</p>}
            {error?.data?.message && <p className='error'>{error?.data?.message}</p>}
        </Box>
    )
}
