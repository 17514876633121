import React, { useState } from 'react'

import CustomButton from '../../../../Components/AdminComponents/CustomButton/CustomButton'
import AddLanguage from './AddLanguage'
import LanguageList from './LanguageList'
import EditLanguage from './EditLanguage'

import { useGetLanguagesQuery } from '../../../../Redux/Language/LanguageAPI'

export default function Language() {
    const [addLanguageView, setAddLanguageView] = useState(false)
    const [editLanguageView, setEditLanguageView] = useState(false)
    const [editLanguageId, setEditLanguageId] = useState(null)

    const { data: languageData, error, isLoading } = useGetLanguagesQuery()

    if (isLoading) return <h1>Loading...</h1>

    return (
        <div className='language-container'>
            <h1>Language</h1>
            {!addLanguageView && !editLanguageView && (
                <CustomButton
                    buttonName={'Add Language'}
                    onClick={() => setAddLanguageView(!addLanguageView)}
                />
            )}
            {addLanguageView && (
                <AddLanguage
                    changeView={() => setAddLanguageView(!addLanguageView)}
                />
            )}
            {editLanguageView && (
                <EditLanguage
                    editLanguageId={editLanguageId}
                    changeView={() => setEditLanguageView(!editLanguageView)}
                />
            )}
            {!addLanguageView && !editLanguageView && (
                <LanguageList
                    languages={languageData}
                    changeView={() => setEditLanguageView(!editLanguageView)}
                    setEditLanguageId={setEditLanguageId}
                />
            )}
        </div>
    )
}
