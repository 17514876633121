import React from 'react'

import Header from '../../Components/ClientComponents/Header'
import { Outlet } from 'react-router'
import Footer from '../../Components/ClientComponents/Footer';



export default function PublicRoot() {
    return (
        <>
            <Header />

            <Outlet />

            <Footer />
        </>
    )
}
