import React, { useEffect, useState } from 'react'

import img_1 from '../../../Assets/cover-eng-one.jpg'
import vid_1 from '../../../Assets/Videos/English-One.mp4'
import img_2 from '../../../Assets/cover-eng-three.jpg'
import vid_2 from '../../../Assets/Videos/English-Two.mp4'

import MyCoursesList from '../Dashboard/MyCoursesList'

import { useGetCoursesQuery } from '../../../Redux/Course/CourseAPI'
import { useGetLanguagesQuery } from '../../../Redux/Language/LanguageAPI'


import CourseVideo from '../../../Components/ClassroomComponents/CourseVideo/CourseVideo'

const Data = [
    {
        langauge: 'English',
        title: 'Introduction to Programming',
        img: img_1,
        category: 'Programming Basics',
        videoLink: vid_1,
        desc: `Introduction to Programming: Get started on your coding journey with this comprehensive introduction to programming. In this video, you'll learn the fundamental concepts of programming, including variables, data types, control structures, and more. Whether you're a complete beginner or looking to refresh your programming skills, this video will provide you with the essential foundation to start writing and understanding code. Join us as we demystify the world of programming and set you on the path to becoming a proficient programmer.`,
    },
    {
        langauge: 'English',
        title: 'How To Start Programming',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Start Programming: Embarking on your programming journey can be both exciting and a little daunting. In this enlightening video, we guide you through the essential steps to kickstart your programming adventure. We cover everything from choosing the right programming language for your goals and setting up your development environment to writing your first lines of code. Whether you're curious about app development, web programming, or simply want to understand the logic behind software, this video will equip you with the knowledge and confidence to take your first steps into the world of programming. Join us and unlock the door to a universe of creative possibilities through coding.`,
    },
    {
        langauge: 'English',
        title: 'How To Pick a Programming Language',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Pick a Programming Language: Choosing a programming language can be a daunting task. With so many options available, it can be difficult to know where to begin. In this video, we'll help you narrow down your options and choose the programming language that's right for you. We'll cover the most popular programming languages and their applications, as well as the factors you should consider when making your decision. Whether you're looking to build a website, develop an app, or simply want to learn a new skill, this video will help you find the perfect programming language for your goals.`,
    },
    {
        langauge: 'English',
        title: 'How To Choose a Project',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Choose a Project: Choosing a project is one of the most important steps in your programming journey. In this video, we'll help you find the perfect project for your skill level and goals. We'll cover the different types of projects you can build, as well as the factors you should consider when choosing your project. Whether you're looking to build a website, develop an app, or simply want to learn a new skill, this video will help you find the perfect project for your goals.`,
    },
    {
        langauge: 'Amharic',
        title: 'Introduction to Programming',
        img: img_1,
        category: 'Programming Basics',
        videoLink: vid_1,
        desc: `Introduction to Programming: Get started on your coding journey with this comprehensive introduction to programming. In this video, you'll learn the fundamental concepts of programming, including variables, data types, control structures, and more. Whether you're a complete beginner or looking to refresh your programming skills, this video will provide you with the essential foundation to start writing and understanding code. Join us as we demystify the world of programming and set you on the path to becoming a proficient programmer.`,
    },
    {
        langauge: 'Amharic',
        title: 'How To Start Programming',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Start Programming: Embarking on your programming journey can be both exciting and a little daunting. In this enlightening video, we guide you through the essential steps to kickstart your programming adventure. We cover everything from choosing the right programming language for your goals and setting up your development environment to writing your first lines of code. Whether you're curious about app development, web programming, or simply want to understand the logic behind software, this video will equip you with the knowledge and confidence to take your first steps into the world of programming. Join us and unlock the door to a universe of creative possibilities through coding.`,
    },
    {
        langauge: 'Amharic',
        title: 'How To Pick a Programming Language',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Pick a Programming Language: Choosing a programming language can be a daunting task. With so many options available, it can be difficult to know where to begin. In this video, we'll help you narrow down your options and choose the programming language that's right for you. We'll cover the most popular programming languages and their applications, as well as the factors you should consider when making your decision. Whether you're looking to build a website, develop an app, or simply want to learn a new skill, this video will help you find the perfect programming language for your goals.`,
    },
    {
        langauge: 'Amharic',
        title: 'How To Choose a Project',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Choose a Project: Choosing a project is one of the most important steps in your programming journey. In this video, we'll help you find the perfect project for your skill level and goals. We'll cover the different types of projects you can build, as well as the factors you should consider when choosing your project. Whether you're looking to build a website, develop an app, or simply want to learn a new skill, this video will help you find the perfect project for your goals.`,
    },
    {
        langauge: 'Oromiffa',
        title: 'How To Choose a Project',
        img: img_2,
        category: 'Programming Basics',
        videoLink: vid_2,
        desc: `How To Choose a Project: Choosing a project is one of the most important steps in your programming journey. In this video, we'll help you find the perfect project for your skill level and goals. We'll cover the different types of projects you can build, as well as the factors you should consider when choosing your project. Whether you're looking to build a website, develop an app, or simply want to learn a new skill, this video will help you find the perfect project for your goals.`,
    },
]

export default function Courses() {
    // const englishCourses = Data.filter(c => c.langauge === 'English')
    // const amharicCourses = Data.filter(c => c.langauge === 'Amharic')
    // const oromiffaCourses = Data.filter(c => c.langauge === 'Oromiffa')


    const { data: Courses, error: courseError, isLoading, courseLoading } = useGetCoursesQuery();
    const { data: Languages, error: langError, isLoading: langLoading } = useGetLanguagesQuery();

    const [languageIds, setLanguageIds] = useState([])

    useEffect(() => {
        console.log(Courses)
    }, [Courses])

    useEffect(() => {
        if (Languages) setLanguageIds(Languages.map(l => l.id))
    }, [Languages])

    return (
        <div id='courses' className='container py-5'>
            <div className='top-section'>
                <div className='title'>
                    <p className='small-title'>Explore Courses</p>
                    <h1>Courses</h1>
                    <p className='desc'></p>
                </div>
            </div>
            {/* <div className='courses-list'>
                <h2>English Courses</h2>
                <div className='courses row'>
                    {englishCourses.map((c, i) => {
                        return <CourseVideo course={c} key={i} />
                    })}
                </div>
            </div>
            <div className='courses-list'>
                <h2>Amharic Courses</h2>
                <div className='courses row'>
                    {amharicCourses.map((c, i) => {
                        return <CourseVideo course={c} key={i} />
                    })}
                </div>
            </div>
            <div className='courses-list'>
                <h2>Oromiffa Courses</h2>
                <div className='courses row'>
                    {oromiffaCourses.map((c, i) => {
                        return <CourseVideo course={c} key={i} />
                    })}
                </div>
            </div> */}

            {
                Courses?.map((c, i) => {
                    return Languages?.map(lan => {
                        return (
                            <div className='col-lg-12' key={i}>
                                <MyCoursesList course={c} language={lan} />
                            </div>
                        )
                    });
                })
            }
        </div>
    )
}
