import React, { useState } from 'react'
import FsLightbox from "fslightbox-react";


export default function PopularCoursesVideo({ course }) {
    const [toggler, setToggler] = useState(false);

    return (
     
     <div className='course-item col-lg-4 col-md-6 col-sm-12'>
          <a href='/login'>
             <div className='course-inner-item'>
                <img src={course.img}></img>
                <p className='category'>{course.category}</p>
                <h5>{course.title}</h5>
                <p className='desc'>{course.desc}</p>
            </div>
            </a>
            {/* <FsLightbox
                toggler={toggler}
                sources={[
                    course.videoLink
                ]}
            /> */}
        </div>
      
    )
}
