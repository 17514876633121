import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Layout, Drawer, Affix } from 'antd'
import Sidenav from '../../Components/ClassroomComponents/SideNav/Sidenav'
import Header from '../../Components/ClassroomComponents/Header/Header'
import Footer from '../../Components/ClassroomComponents/Footer/Footer'

import 'antd/dist/antd.css'
// import '../../Styles/ClassroomStyles/main.css'
// import '../../Styles/ClassroomStyles/responsive.css'

import { Outlet } from 'react-router-dom'

const { Header: AntHeader, Content, Sider } = Layout

export default function ClassroomRoot() {
    const navigate = useNavigate()
    // check if user has logged in by pulling login_status from local storage
    // if user has not logged in, redirect to login page

    useEffect(() => {
        const loginStatus = localStorage.getItem('login_status')
        if (loginStatus !== 'User has logged in.') {
            navigate('/login')
        }
    }, [])

    document.body.setAttribute('id', 'classroom-root')

    const [visible, setVisible] = useState(false)
    const [placement, setPlacement] = useState('right')
    const [sidenavColor, setSidenavColor] = useState('#f8b04a')
    const [sidenavType, setSidenavType] = useState('transparent')
    const [fixed, setFixed] = useState(false)

    const openDrawer = () => setVisible(!visible)
    const handleSidenavType = type => setSidenavType(type)
    const handleSidenavColor = color => setSidenavColor(color)
    const handleFixedNavbar = type => setFixed(type)

    let { pathname } = useLocation()

    const pages = pathname.split('/')
    const currentPage = pages[pages.length - 1]
    const parentPages = pages.splice(1, pages.length - 1)

    console.log(pathname)

    pathname = pathname.replace('/', '')

    useEffect(() => {
        if (pathname === 'rtl') {
            setPlacement('left')
        } else {
            setPlacement('right')
        }
    }, [pathname])

    return (
        <div>
            <Layout
                className={`layout-dashboard ${currentPage === 'account' ? 'layout-profile' : ''
                    } ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''}`}
            >
                <Drawer
                    title={false}
                    placement={placement === 'right' ? 'left' : 'right'}
                    closable={false}
                    onClose={() => setVisible(false)}
                    visible={visible}
                    key={placement === 'right' ? 'left' : 'right'}
                    width={250}
                    className={`drawer-sidebar ${pathname === 'rtl' ? 'drawer-sidebar-rtl' : ''
                        } `}
                >
                    <Layout
                        className={`layout-dashboard ${pathname === 'rtl' ? 'layout-dashboard-rtl' : ''
                            }`}
                    >
                        <Sider
                            trigger={null}
                            width={250}
                            theme='light'
                            className={`sider-primary ant-layout-sider-primary ${sidenavType === '#fff' ? 'active-route' : ''
                                }`}
                            style={{ background: sidenavType }}
                        >
                            <Sidenav color={sidenavColor} />
                        </Sider>
                    </Layout>
                </Drawer>
                <Sider
                    breakpoint='lg'
                    collapsedWidth='0'
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type)
                    }}
                    trigger={null}
                    width={250}
                    theme='light'
                    className={`sider-primary ant-layout-sider-primary ${sidenavType === '#fff' ? 'active-route' : ''
                        }`}
                    style={{ background: sidenavType }}
                >
                    <Sidenav color={sidenavColor} />
                </Sider>
                <Layout>
                    <AntHeader className={`${fixed ? 'ant-header-fixed' : ''}`}>
                        <Header
                            onPress={openDrawer}
                            name={parentPages}
                            subName={currentPage}
                            handleSidenavColor={handleSidenavColor}
                            handleSidenavType={handleSidenavType}
                            handleFixedNavbar={handleFixedNavbar}
                        />
                    </AntHeader>
                    <Content className='content-ant'>
                        <Outlet />
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        </div>
    )
}
