import React from "react";
import { Add } from "@mui/icons-material";

export default function CustomButton({ buttonName, onClick, type, icon, classname }) {
    return (
        <button type={type} className={` ${classname} custom-button`}  onClick={() => onClick()}>
            <div>
                { icon != null ? icon : <Add /> }
                <h3>{buttonName}</h3>
            </div>
        </button>
    );
}
