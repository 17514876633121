import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';


import feedbackImg1 from "../../Assets/consult-lady.png";
import feedbackImg2 from "../../Assets/consult-lady.png";



const feedbacks = [
    {
        "img": feedbackImg1,
        "name": "Leule Mesfin",
        "course": "Introduction to Data Science",
        "testimonial": "The Introduction to Data Science course was excellent! It provided a comprehensive understanding of data manipulation, visualization, and basic machine learning techniques. The instructor was clear and engaging, and the practical assignments helped me apply the concepts effectively. I highly recommend this course to anyone starting in the field of data science."
    },
    {
        "img": feedbackImg2,
        "name": "Rewina Teklay",
        "course": "Full-Stack Web Development",
        "testimonial": "Taking the Full-Stack Web Development course was a game-changer for my career. I learned how to build modern web applications from scratch, and the hands-on projects gave me the confidence to work with both front-end and back-end technologies. The course content was up-to-date and relevant, making it easy to grasp the concepts. I'm now a proficient web developer, all thanks to this course!"
    },
    {
        "img": feedbackImg1,
        "name": "Melat Ambachew",
        "course": "Mobile App Development Fundamentals",
        "testimonial": "As someone new to mobile app development, I found the Mobile App Development Fundamentals course extremely helpful. The course covered both Android and iOS platforms, and the step-by-step guidance made it easy to follow along. By the end of the course, I developed my first app and gained valuable insights into app design and deployment. I'm excited to continue exploring the world of mobile app development."
    },
    {
        "img": feedbackImg2,
        "name": "Isaac Getenet",
        "course": "Graphic Design Principles",
        "testimonial": "The Graphic Design Principles course was a great starting point for me as a graphic design enthusiast. It taught me the fundamental principles of design and how to use them effectively in my projects. The course projects and feedback from the instructor helped me improve my skills and creativity. Now, I'm confident in my ability to create visually appealing designs for various purposes."
    },
    {
        "img": feedbackImg1,
        "name": "Lelise Demessie",
        "course": "Machine Learning Foundations",
        "testimonial": "The Machine Learning Foundations course was challenging but highly rewarding. The course covered a wide range of algorithms and techniques, and the real-world examples made the learning process engaging. The instructors were knowledgeable and responsive to questions. This course has given me a solid foundation in machine learning, and I'm excited to apply it to real-world problems."
    },
    {
        "img": feedbackImg2,
        "name": "Michael Anderson",
        "course": "Cybersecurity Essentials",
        "testimonial": "I can't recommend the Cybersecurity Essentials course enough! It covered essential topics like network security, encryption, and best practices to secure systems. The instructors were cybersecurity experts who explained complex concepts in a clear and concise manner. The hands-on labs allowed me to put theory into practice, making me more confident in protecting myself and my organization from cyber threats."
    },
    {
        "img": feedbackImg1,
        "name": "Ermias Getachew",
        "course": "Game Development with Unity",
        "testimonial": "I always wanted to create my own games, and the Game Development with Unity course helped me turn that dream into reality. The course covered all aspects of game development, from creating game mechanics to optimizing performance. The projects were fun, and the support from the course community was fantastic. Now, I'm on my way to becoming a proficient game developer!"
    },
    {
        "img": feedbackImg2,
        "name": "Amisiyas Teferi",
        "course": "Cloud Computing Basics",
        "testimonial": "The Cloud Computing Basics course was an eye-opener for me. It introduced me to cloud architecture, deployment models, and the major cloud service providers. The practical exercises on cloud platforms allowed me to gain hands-on experience. This course has been a great starting point for my cloud computing journey, and I'm eager to explore more advanced cloud concepts."
    }
]



export default function Feedback() {
    return (
        <div id='feedback' className='container py-5'>
            <div className='title'>
                <h2>Student <span>Feedback</span></h2>
            </div>
            <div>
                <Swiper
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                    }}
                    modules={[FreeMode, Pagination]}
                    className="feedbacks"
                >
                    {
                        feedbacks.map((fb, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div className='feedback-item'>
                                        <div className='header'>
                                            <div className='img-wrapper'>
                                                <img src={fb.img}></img>
                                            </div>
                                            <div className='header-text'>
                                                <h6>{fb.name}</h6>
                                                <p>{fb.course}</p>
                                            </div>
                                        </div>
                                        <p className='testimony'>{fb.testimonial}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}
