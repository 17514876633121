import React, { useEffect, useState } from 'react';

import logo from '../../Assets/logo.png';
import { routeObject } from '../../router';

export default function Header() {

  const [isScrolled, setIsScrolled] = useState(false);

  const location = window.location.pathname;

  window.onscroll = (event) => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }
  useEffect(() => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    }
  }, [])

  return (
    <header id='header' className={`fixed-top ${isScrolled ? 'header-scrolled' : ''} navbar navbar-expand-lg`}>
      <div className='container'>
        <div className='logo float-start navbar-brand'>
          <a href='/'>
            <img src={logo} alt='' className='img-fluid'></img>
            <span>- 8832</span>
          </a>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <nav class="collapse navbar-collapse main-nav  justify-content-end  float-end" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 mb-lg-0">
            {routeObject
              .filter((route) => route.isPublic)
              .map((route, i) => {
                console.log(route);
                let publicMenuItems = route.children.filter((route) => route.isMainMenu)
                return publicMenuItems.map((pr, i) => {
                  return (
                    <li key={i} className={`${pr.path === location ? "active" : ""}`}>
                      <a href={pr.path} >
                        {pr.name}
                      </a>
                    </li>
                  );
                })

              })}
            <li >
              <a className='login' href="/login">
                My Classroom
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )



}
// return (
//   <header id='header' className={`fixed-top ${isScrolled ? 'header-scrolled' : ''}`}>
//     <div className='container'>
//       <div className='logo float-start'>
//         <a href='/'>
//           <img src={logo} alt='' className='img-fluid'></img>
//           <span>- 8832</span>
//         </a>
//       </div>
//       <nav className='main-nav float-end d-lg-block'>
//         <ul>
//           {routeObject
//             .filter((route) => route.isPublic)
//             .map((route, i) => {
//               console.log(route);
//               let publicMenuItems = route.children.filter((route) => route.isMainMenu)
//               return publicMenuItems.map((pr, i) => {
//                 return (
//                   <li key={i} className={`${pr.path === location ? "active" : ""}`}>
//                     <a href={pr.path} >
//                       {pr.name}
//                     </a>
//                   </li>
//                 );
//               })

//             })}
//           <li >
//             <a className='login' href="/login">
//               My Classroom
//             </a>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   </header>
// )
/*
Explain
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
*/