import React from 'react'
import { Outlet } from 'react-router'
import SideNav from '../../../Components/AdminComponents/SideNav/SideNav'
import RightPanel from '../../../Components/AdminComponents/RightPanel/RightPanel'
import { useDispatch, useSelector } from 'react-redux'
import { changeTheme, showTheme } from '../../../Redux/Theme/Theme'
import { useGetUserQuery } from '../../../Redux/User/UserAPI'

export default function Dashboard() {
    const dispatch = useDispatch()

    let theme = useSelector(showTheme)
    let user = useGetUserQuery(JSON.parse(localStorage.getItem('user_info')).id)

    let updateTheme = () => {
        dispatch(changeTheme())
    }

    return (
        <div id='admin-dashboard-main'>
            <SideNav />
            <div className='admin-middle-view'>
                <Outlet />
            </div>

            {/* <RightPanel
                updateTheme={updateTheme}
                isDarkTheme={theme.isDarkMode}
            /> */}
        </div>
    )
}
