import { createBrowserRouter } from 'react-router-dom'

import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList'

import Home from './Pages/Public/Home/Home'
import About from './Pages/Public/About/About'
import ContactUs from './Pages/Public/ContactUs/ContactUs'
import PublicRoot from './Pages/Public/PublicRoot'
import Login from './Pages/Public/Login/Login'

import AdminRoot from './Pages/Admin/AdminRoot'
import AdminDashboard from './Pages/Admin/Dashboard/Dashboard'
import Courses from './Pages/Admin/Dashboard/Courses/Courses'
import CourseCategory from './Pages/Admin/Dashboard/CourseCategory/CourseCategory'
import Language from './Pages/Admin/Dashboard/Language/Language'
import Video from './Pages/Admin/Dashboard/Video/Videos'

import ClassroomRoot from './Pages/Classroom/ClassroomRoot'
import ClassRoomDashBoard from './Pages/Classroom/Dashboard/Dashboard'
import ClassRoomCourses from './Pages/Classroom/Courses/Courses'
import ClassRoomAccount from './Pages/Classroom/Account/Account'
import CoursePage from './Pages/Classroom/Courses/CoursePage'

export const routeObject = [
    {
        path: '/',
        element: <PublicRoot />,
        isPublic: true,
        children: [
            {
                path: '/',
                name: 'Home',
                element: <Home />,
                isMainMenu: true,
            },
            {
                path: '/About',
                name: 'About',
                element: <About />,
                isMainMenu: true,
            },
            {
                path: '/ContactUs',
                name: 'Contact Us',
                element: <ContactUs />,
                isMainMenu: true,
            },
        ],
    },
    {
        path: '/login',
        name: 'Log In',
        element: <Login />,
    },
    {
        path: '/classroom',
        name: 'Classroom',
        element: <ClassroomRoot />,
        children: [
            {
                path: '/classroom/',
                name: 'Dashboard',
                element: <ClassRoomDashBoard />,
            },
            {
                path: '/classroom/Courses',
                name: 'Courses',
                element: <ClassRoomCourses />,
            },
            {
                path: '/classroom/Courses/:courseId/:languageId',
                name: 'Course Page',
                element: <CoursePage />,
            },
            {
                path: '/classroom/account',
                name: 'Account',
                element: <ClassRoomAccount />,
            },
        ],
    },
    {
        path: '/Admin',
        name: 'Admin',
        element: <AdminRoot />,
        isAdmin: true,
        children: [
            {
                path: '/Admin/',
                name: 'Admin Dashboard',
                element: <AdminDashboard />,
                children: [
                    {
                        path: '/Admin/Dashboard/Language',
                        name: 'Language',
                        element: <Language />,
                        isSideNav: true,
                    },
                    {
                        path: '/Admin/Dashboard/CourseCategory',
                        name: 'Course Category',
                        element: <CourseCategory />,
                        isSideNav: true,
                    },
                    {
                        path: '/Admin/Dashboard/Courses',
                        index: true,
                        name: 'Courses',
                        element: <Courses />,
                        isSideNav: true,
                    },
                    {
                        path: '/Admin/Dashboard/Video',
                        name: 'Video',
                        element: <Video />,
                        isSideNav: true,
                    },
                ],
            },
        ],
    },
]

const router = createBrowserRouter(routeObject)

export default router
