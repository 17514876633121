import React from 'react'
import ContactUsText from './ContactUsText'
import ContactUsForm from './ContactUsForm'

export default function ContactUs() {
  return (
    <div id='contct-us'>
      <ContactUsText />
      <ContactUsForm />
      <div className="container-fluid bitapps-location-map">
        <iframe
        title="bitapps-location"
          className="map"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Addis%20Ababa%20ethiopa%20Bitapps%20tech&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </div>
    </div>
  )
}
