import React from "react";

export default function ContactUsForm() {
  return (
    <div id="contact-us-form" className="container py-5">
      <div className="title">
        <h2>Fostering a playful & engaging learning environment</h2>
      </div>
      <form className="">
        <div className="top">
          <input className="name" placeholder="Your Name"></input>
          <input className="email" placeholder="Email"></input>
        </div>
        <div className="bottom">
          <textarea placeholder="Message"></textarea>
          <a href="">Submit</a>
        </div>
      </form>

      <div className="waves">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className="waves">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
